import React, { useEffect } from "react";

import { CategoryBadge } from "../components/CategoryBadge";

export default function PageNotFound() {
  useEffect(() => {
    document.body.classList.add("page-not-found");

    return () => {
      document.body.classList.remove("page-not-found");
    };
  });

  return (
    <>
      <main>
        <div className="not-found__panel trapezium">
          <CategoryBadge category="Error" text="404 Page Not Found" />
          <h1>Whoops, the poll or page you were looking for does not exist.</h1>
          <h2>Ah, the dreaded 404.</h2>
          <p>Let's get you back to the polls.</p>
          <a href="/" className="button-link btn--pop-primary">
            <svg className="icon" viewBox="0 0 24 24">
              <use href="#icon-OTC"></use>
            </svg>
            Home page
          </a>
        </div>
      </main>
    </>
  );
}
