function ChoiceBar({ choice, chartColor, percentage, children }) {
  return (
    <div className="poll-card__choice choice-style-bar" id={choice._id}>
      <div className="choice-layout">
        <h2>{choice.name}</h2>
        <div className="percentage-bar">
          <div className="percentage-bar__outer">
            <div
              className="percentage-bar__inner"
              style={{
                width: `${percentage}%`,
                backgroundColor: `${chartColor}`
              }}
            ></div>
          </div>
          <span>{percentage}%</span>
        </div>
      </div>
      <div className="poll-card__choice--count">
        {choice.count}
        {choice?.count === 1 ? " vote" : " votes"}
      </div>
      {children}
    </div>
  );
}

export { ChoiceBar };
