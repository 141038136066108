import Tick from "../icons/TickIcon";

function VotePanel({ choice, vote }) {
  return (
    <div className="poll-card__vote-panel" id={choice._id}>
      <h2>{choice.name}</h2>
      <button className="btn--pop-primary btn--full" onClick={() => vote(choice._id)}>
        <Tick /> Vote
      </button>

      {/*<div className="poll-card__choice--count">*/}
      {/*  {choice.count} {choice.count === 1 ? " vote" : " votes"}*/}
      {/*</div>*/}
    </div>
  );
}

export { VotePanel };
