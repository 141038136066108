import React from "react";
import { useQRCode } from "next-qrcode";

function QRCodeImage(props) {
  const { Image } = useQRCode();

  return (
    <Image
      text={props.text}
      options={{
        type: "image/png",
        quality: 1,
        level: "H",
        margin: 4,
        scale: 4,
        width: 256,
        color: {
          dark: "#000000ff",
          light: "#ffffff"
        },
        ...props?.options
      }}
      className="qr-code-image"
    />
  );
}

export { QRCodeImage };
