import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ModalService } from "./ModalService";

function ModalRoot(props) {
  const nodeRef = useRef(null);
  const [modal, setModal] = useState({});
  const [openModal, setOpenModal] = useState(false);

  function closeModal(event, key) {
    if (event.target.matches(".modal__overlay") || event.target.matches(".modal__close")) {
      return modal.close();
    }
    if (key) {
      return modal.close();
    }
  }

  useEffect(() => {
    ModalService.on("open", ({ component, props }) => {
      setModal({
        component,
        props,
        close: (value) => {
          setOpenModal(false);
          setTimeout(() => setModal({}), 400);
        }
      });
      setOpenModal(true);
    });
  }, []);

  const ModalComponent = modal.component ? modal.component : null;
  return (
    <CSSTransition nodeRef={nodeRef} in={openModal} unmountOnExit timeout={{ enter: 0, exit: 350 }}>
      <div ref={nodeRef} className={modal.component ? "modal__overlay" : ""} onClick={closeModal}>
        {ModalComponent && (
          <ModalComponent
            {...modal.props}
            close={closeModal}
            // className={ModalComponent ? "stylo" : ""}
          />
        )}
      </div>
    </CSSTransition>
  );
}

export { ModalRoot };
