import React from "react";

function PollTitle({ Tag, title, children }) {
  return (
    <header className="poll-card__header">
      <Tag className="poll-card__title">{title}?</Tag>
      {children}
    </header>
  );
}

export { PollTitle };
