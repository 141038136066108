import React, { useRef } from "react";

function CategoryBadge({ category, text }) {
  const categoryTextRef = useRef(null);

  function categoryToggle(event) {
    if (event.target.dataset.setWidth === "false") {
      event.target.dataset.setWidth = "true";
      //const categoryText = document.querySelector(".category-text");
      const elWidth = categoryTextRef.current.offsetWidth + 82;
      categoryTextRef.current.parentNode.setAttribute("style", `max-width: ${elWidth}px`);
      return;
    }
    event.target.style.maxWidth = "66px";
    event.target.dataset.setWidth = "false";
  }

  return (
    <div
      className="category-badge"
      data-set-width="false"
      style={{ maxWidth: "66px" }}
      {...(category ? { "data-category": category } : {})}
      onClick={categoryToggle}
    >
      <span>
        <svg className={`icon icon-${category}`} viewBox="0 0 32 32">
          <use href={`#icon-${category}`}></use>
        </svg>
      </span>
      <span className="category-text" ref={categoryTextRef}>
        {text ? text : category}
      </span>
    </div>
  );
}

export { CategoryBadge };
