function Modal({ category, className, children }) {
  return (
    <div className={`modal ${className}`}>
      <div className="modal__left"></div>
      <div className="modal__right">
        <div className="modal__category-fade" data-category={category}>
          <svg viewBox="0 0 32 32">
            <use href={`#icon-${category}`}></use>
          </svg>
        </div>
      </div>
      {children}
    </div>
  );
}

export { Modal };
