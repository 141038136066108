import React, { useEffect } from "react";

import pollReviewImg from "../images/poll-review-panel.svg";
import createPollImg from "../images/create-new-poll-form.svg";
import pollSuccessImg from "../images/poll-creation-success.svg";
import pollSuccessEmail from "../images/poll-success-email-sent.svg";
import pollViewVoteImg from "../images/poll-view-vote.svg";
import pollViewOnlyImg from "../images/poll-view-view-only.svg";

import { CategoryBadge } from "../components/CategoryBadge";

export default function Help() {
  useEffect(() => {
    document.body.classList.add("page-help");

    return () => {
      document.body.classList.remove("page-help");
    };
  });

  return (
    <>
      <main>
        <div className="help__panel trapezium">
          <CategoryBadge category="Help" text="Help" />
          <h1>OFF THE CUFF POLLS – Help</h1>
          <p>
            <strong>Important Notice:</strong> Please be aware the <strong>polls</strong> on this
            site are <strong>not password protected</strong>. Please <strong>do not</strong> use any{" "}
            <strong>content</strong> you would rather keep <strong>private or confidential.</strong>{" "}
            The polls are shared via a fun, <strong>very difficult to guess</strong> access link,
            generated during a successful poll submission. Polls are secured using cookies to
            prevent duplicate votes being cast. <strong>Voting</strong> on polls is open for{" "}
            <strong>fifteen days</strong> and will automatically close.
          </p>

          <h2>How to craft the perfect online poll!</h2>
          <p>
            The poll question should be short, succinct and to the point. Check to make sure it
            makes sense. Avoid over-loading the choices and keep them to an absolute minimum. Four
            to six choices are ideal (maximum of ten). Keep them short, sweet and on topic.
          </p>
          <p>
            Review your copy and write it down in a text editor utilising its spell checker before
            cutting and pasting it into the poll form. Take your time and avoid rushing the process.
            Decide if you want to share it with the world or just colleagues, friends or family.
          </p>
          <h2>The home page</h2>
          <p>
            The home page displays all polls created with the <strong>‘Show on home page’</strong>{" "}
            check box selected. They are ordered by the latest date descending. An action button on
            the poll card will indicate its current status. A green button allows voting, while a
            blue one means viewable only as voting has closed or you have already voted. A green
            ticked circle located at the bottom indicates you have voted on that poll.
          </p>
          <h2>The create poll page</h2>
          <p>
            We have tried making poll creation as simple and as streamlined as possible. You can
            only use letters, numbers and commas in the question and option input fields. Please
            submit the poll content in English.
          </p>
          <ol>
            <li>
              Use the Poll review panel to double-check your question and choices, plus any updates
              you might make before submitting your poll. <strong>Error messages</strong> generated
              while creating your poll are also shown in this panel.
            </li>
          </ol>
          <img className="poll-review-panel" src={pollReviewImg} alt="Poll Review panel" />
          <ol start="2">
            <li>
              Enter the poll question here. It requires a minimum of <strong>ten</strong>{" "}
              characters. <strong>Please note:</strong> A question mark will be added automatically.
            </li>
            <li>
              The poll must contain at least <strong>two</strong> choices with a minimum of{" "}
              <strong>two</strong> characters in each input. <strong>Please note:</strong> Question
              and choices can only contain letters, numbers and commas.
            </li>
            <li>Determine a category for the poll that matches the topic of the question.</li>
            <li>
              <strong>Deselect</strong> the <strong>‘Show on home page’</strong> check box if you{" "}
              <strong>do not</strong> want your poll to be public. <strong>Please note:</strong> You
              will not be allowed to change this at a later date.
            </li>
            <li>
              Please <strong>select</strong> the required validation{" "}
              <strong>‘I'm not a robot’</strong> check box within the <strong>reCAPTCHA</strong>{" "}
              panel.
            </li>
            <li>
              Click the <strong>‘Create New Poll’</strong> to complete your poll.
            </li>
          </ol>
          <img src={createPollImg} alt="Create Poll form" />
          <p>
            On submission, the poll is validated for errors before being saved. If the process is
            successful, your screen will change to display the following.{" "}
            <span>
              Please take note of the instructions shown on your screen, as they will not be shown
              to you again.
            </span>
          </p>
          <img src={pollSuccessImg} alt="Poll created successfully screen" />
          <p>
            If you choose to send the link via email, please ensure you enter the{" "}
            <strong>correct address.</strong> If your email has been sent successfully, your screen
            will change to display the following.
          </p>
          <img src={pollSuccessEmail} alt="Email sent successfully message" />
          <p>You can visit your poll via the copied link you actioned earlier.</p>
          <h3>
            Now share your poll with colleagues, friends or family and don’t forget to review the
            results.
          </h3>
          <div className="help__panel--view">
            <h2>Poll page</h2>
            <div>
              <p>
                When you first visit a poll and have yet to vote you will be presented with poll
                vote view. Here, you can read the choices and cast your vote. Your vote will be
                registered and the screen will change to show the updated results.
              </p>
              <img src={pollViewVoteImg} alt="Poll vote" />
            </div>
            <div>
              <p>
                If you have voted on the poll or the poll has expired, you will be presented with
                poll view screen. This view will show the current or if expired the final results.
              </p>
              <img src={pollViewOnlyImg} alt="Poll view" />
              <p>
                The poll view screen contains action buttons that will allow you to do the
                following:
              </p>
              <ul>
                <li>
                  <svg className="icon icon-otc" viewBox="0 0 24 24">
                    <use href="#icon-OTC"></use>
                  </svg>
                  Info <span>View poll information in a pop-up modal</span>
                </li>
                <li>
                  <svg className="icon icon-style" viewBox="0 0 24 24">
                    <use href="#icon-Style"></use>
                  </svg>
                  Style <span>Change the render style of the choices</span>
                </li>
                <li>
                  <svg className="icon icon-layout" viewBox="0 0 24 24">
                    <use href="#icon-Layout-Stack"></use>
                  </svg>
                  Layout <span>Change the render layout of the choices</span>
                </li>
                <li>
                  <svg className="icon icon-chart" viewBox="0 0 24 24">
                    <use href="#icon-Chart"></use>
                  </svg>
                  Chart <span>Show results as a bar chart in a pop-up modal</span>
                </li>
                <li>
                  <svg className="icon icon-sort" viewBox="0 0 24 24">
                    <use href="#icon-Sort-Asc"></use>
                  </svg>
                  Sort <span>Reverse the order of the choices</span>
                </li>
                <li>
                  <svg className="icon icon-share" viewBox="0 0 24 24">
                    <use href="#icon-Share"></use>
                  </svg>
                  Share <span>Show the share options in a pop-up modal</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
