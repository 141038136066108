import React from "react";

function DeleteIcon(props) {
  const title = props.title || "Delete";
  const className = props.className || "icon-delete";

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill="#212121">
        <g
          id="Iconly/Broken/Delete"
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <path
            id="Delete"
            d="M15.992 6.733a.726.726 0 0 1 .653.79c-.005.066-.534 6.791-.84 9.612-.187 1.74-1.31 2.797-3 2.828-1.286.024-1.598.037-2.884.037l-.73-.001a.723.723 0 0 1-.712-.735.721.721 0 0 1 .714-.728h.363c.456.002.823 0 1.171-.003l.519-.006c.435-.006.9-.015 1.532-.026.974-.019 1.5-.518 1.608-1.526.303-2.8.832-9.504.837-9.57a.73.73 0 0 1 .769-.672zm-13.984 0a.73.73 0 0 1 .769.672c.005.067.53 6.752.836 9.575.106.987.625 1.497 1.542 1.517a.725.725 0 0 1 .699.747.723.723 0 0 1-.714.716h-.015c-1.624-.035-2.747-1.114-2.931-2.817-.309-2.846-.834-9.554-.84-9.622a.726.726 0 0 1 .654-.788zM11.068 0c.888 0 1.668.614 1.898 1.494a.734.734 0 0 1-.504.897.712.712 0 0 1-.874-.518.542.542 0 0 0-.52-.409H6.932a.542.542 0 0 0-.52.409l-.227 1.173c-.032.158-.08.306-.139.45h11.24c.394 0 .714.328.714.732 0 .405-.32.732-.714.732H.713A.722.722 0 0 1 0 4.228c0-.404.319-.731.713-.731h3.194a.904.904 0 0 0 .878-.737l.238-1.22C5.263.614 6.044 0 6.932 0z"
            fill="currentcolor"
            fillRule="nonzero"
            transform="translate(3 2)"
          />
        </g>
      </g>
    </svg>
  );
}

export default DeleteIcon;
