import React, { useRef } from "react";

function MobileNavButton(props) {
  const btn = useRef(null);

  function handleMobileNav(event) {
    let hiddenHeight = props.element.current.firstElementChild.getBoundingClientRect().height;
    btn.current.classList.toggle("active");
    if (props.element.current.style.maxHeight) {
      props.element.current.style.maxHeight = null;
    } else {
      props.element.current.style.maxHeight = `${hiddenHeight}px`;
    }
  }
  return (
    <div className="mobile-nav-btn" ref={btn} onClick={handleMobileNav}>
      <div className="mnb1"></div>
      <div className="mnb2"></div>
      <div className="mnb3"></div>
    </div>
  );
}

export { MobileNavButton };
