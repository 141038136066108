import React from "react";

function ArrowDownIcon(props) {
  const title = props.title || "Arrow Down";
  const className = props.className || "icon-arrow-down";

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g>
        <g
          id="Iconly/Broken/Arrow-Down"
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <path
            id="Arrow-Down"
            d="M4.242 7.744a.814.814 0 0 1 1.164.002l7.177 7.326a.846.846 0 0 1-.003 1.184.812.812 0 0 1-1.163-.002L4.241 8.926a.847.847 0 0 1 0-1.183zm14.35.002a.814.814 0 0 1 1.165-.002.847.847 0 0 1 .004 1.184l-4.766 4.865a.813.813 0 0 1-1.163.002.847.847 0 0 1-.004-1.183z"
            fillRule="nonzero"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}

export default ArrowDownIcon;
