import React from "react";

import { categories } from "../utils/categoriesArray";
import ArrowDownIcon from "../icons/ArrowDownIcon";

function Select(props) {
  const { onChange, selectedCategory } = props;

  return (
    <>
      <label htmlFor="poll__category">* Poll category</label>
      <div className="select">
        <ArrowDownIcon />
        <select onChange={onChange} value={selectedCategory} data-value={selectedCategory}>
          <option value="">Required - please select a category</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
        <span className="focus"></span>
      </div>
    </>
  );
}

export { Select };
