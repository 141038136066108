function ButtonGeneral({ action, children, label, icon, className, style }) {
  return (
    <button className={className} onClick={action} style={style}>
      {icon && (
        <svg viewBox="0 0 32 32">
          <use href={`#icon-${icon}`}></use>
        </svg>
      )}
      {children} {label}
    </button>
  );
}

export { ButtonGeneral };
