import React from "react";

function PlusIcon(props) {
  const title = props.title || "Plus";
  const className = props.className || "icon-plus";

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill="#212121">
        <g
          id="Iconly/Broken/Plus"
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <path
            id="Plus"
            d="M13.755 0C17.59 0 20 2.392 20 6.253v2.568a.756.756 0 0 1-.765.757V9.56a.765.765 0 0 1-.765-.765V6.253c0-3.052-1.671-4.723-4.715-4.723H6.253C3.21 1.53 1.53 3.21 1.53 6.253v7.502c0 3.035 1.68 4.715 4.723 4.715h7.502c3.044 0 4.715-1.68 4.715-4.715a.765.765 0 0 1 1.53 0C20 17.608 17.608 20 13.755 20H6.253C2.393 20 0 17.608 0 13.755V6.253C0 2.393 2.392 0 6.253 0zM10.07 11.645a.756.756 0 0 1 .74.791v.968l-.014.12a.765.765 0 0 1-.778.618.774.774 0 0 1-.74-.791v-.968a.765.765 0 0 1 .792-.738zm-.035-5.796c.085 0 .169.014.248.041a.765.765 0 0 1 .544.733l-.001 2.391 2.604.001a.756.756 0 0 1 .255.041.759.759 0 0 1 .537.733.765.765 0 0 1-.748.765H6.658a.775.775 0 0 1-.206-.033.773.773 0 0 1-.586-.74.757.757 0 0 1 .757-.766H9.26V6.614a.783.783 0 0 1 .774-.765z"
            fill="currentcolor"
            fillRule="nonzero"
            transform="translate(2 2)"
          />
        </g>
      </g>
    </svg>
  );
}

export default PlusIcon;
