import React from "react";

function ActionButton({ label, icon, trackedState, action }) {
  return (
    <button onClick={action}>
      <svg className={`icon icon-${label}`} viewBox="0 0 24 24">
        {!Array.isArray(icon) && <use href={`#icon-${icon}`}></use>}
        {Array.isArray(icon) && trackedState !== true && (
          <use href={`#icon-${icon[0]}`}></use>
        )}
        {Array.isArray(icon) && trackedState === true && (
          <use href={`#icon-${icon[1]}`}></use>
        )}
      </svg>
      <span>{label}</span>
    </button>
  );
}

export { ActionButton };
