import { CreatedDate } from "../CreatedDate";
import { Modal } from "./Modal";
import { CategoryBadge } from "../CategoryBadge";
import { ButtonGeneral } from "../ButtonGeneral";

function ModalInfo({ title, poll, closeModal }) {
  function getPollDataValues(choices) {
    // Total for all choices
    const total = choices.reduce((acc, choice) => {
      return acc + choice.count;
    }, 0);
    const mostVotes = Math.max(...choices.map((choice) => choice.count));
    const favChoice = choices.find((choice) => choice.count === mostVotes).name;
    let favChoicePercentage = Math.round((mostVotes / total) * 100);
    if (isNaN(favChoicePercentage)) favChoicePercentage = 0;
    return [total, mostVotes, favChoice, favChoicePercentage];
  }

  const pollDataValues = getPollDataValues(poll?.choices);

  return (
    <Modal className="modal__info" category={poll?.category}>
      <div className="modal__content">
        <CategoryBadge category={poll?.category} />
        <p className="modal__sub-head">{title}</p>
        <h1 className="modal__title">{poll?.title}?</h1>
        <hr className="modal__hr" />
        <p>
          Created on <CreatedDate addStatusText date={poll?.createdAt} />{" "}
          {poll?.documentStatus === "expired"
            ? "Voting has closed for this poll."
            : "Voting is open for this poll."}
        </p>
        <p>
          This Poll belongs in the <strong>‘{poll?.category}’</strong> category.
        </p>
        {pollDataValues[0] > 0 ? (
          <p>
            This poll has garnered <strong>{pollDataValues[0]}</strong>{" "}
            {pollDataValues[0] === 1 ? "vote" : "votes"}, and{" "}
            <strong>
              {pollDataValues[3]}% ({pollDataValues[1]} {pollDataValues[0] === 1 ? "vote" : "votes"}
              )
            </strong>{" "}
            of the participants voted for <strong>‘{pollDataValues[2]}’</strong>,{" "}
            {poll?.documentStatus === "active" ? "currently " : null}the most popular option.
          </p>
        ) : (
          <p>This is sad. No votes have been cast for this poll.</p>
        )}
        {poll?.hasVoted ? (
          poll?.votedDate ? (
            <p>
              You voted on this poll on <CreatedDate date={poll?.votedDate} /> at{" "}
              <strong>
                {new Date(poll?.votedDate).toLocaleTimeString([], {
                  timeStyle: "short",
                  hour12: true
                })}
              </strong>{" "}
              for{" "}
              <strong>
                ‘{poll?.choices.find((choice) => choice._id === poll?.votedFor)?.name}’
              </strong>
              .
            </p>
          ) : (
            <p>You voted on this poll.</p>
          )
        ) : (
          <p>You did not vote on this poll.</p>
        )}
        <ButtonGeneral
          className="modal__close btn--pop-primary"
          action={closeModal}
          label="Close"
          icon="Close"
        />
      </div>
    </Modal>
  );
}

export { ModalInfo };
