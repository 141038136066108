import SuccessIcon from "../icons/SuccessIcon";
import CloseIcon from "../icons/CloseIcon";

function AlertSuccess({ success, poll, handleAlertClose }) {
  return (
    <div className="alert alert__success">
      <SuccessIcon className="alert__icon" />
      <div className="alert__content">
        <h2 className="alert__title">{success.message}</h2>
        <p>
          You voted for ‘
          {success.choice && poll?.choices.find((choice) => choice._id === success.choice)?.name}
          ’. Thank you, for participating in this poll!
        </p>
      </div>
      <div className="close" onClick={handleAlertClose}>
        <CloseIcon />
      </div>
    </div>
  );
}

export { AlertSuccess };
