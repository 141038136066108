const choiceChartColorArray = [
  [
    "#fc111f4d",
    "#2b69e94d",
    "#0cdd574d",
    "#f0c2204d",
    "#f9007b4d",
    "#00a2ff4d",
    "#88d72e4d",
    "#be3ac54d",
    "#00c6c54d",
    "#c2ce0f4d"
  ],
  [
    "#fc111fff",
    "#2b69e9ff",
    "#0cdd57ff",
    "#f0c220ff",
    "#f9007bff",
    "#00a2ffff",
    "#88d72eff",
    "#be3ac5ff",
    "#00c6c5ff",
    "#c2ce0fff"
  ]
];

const categoriesColorsArray = [
  "#fc111f",
  "#ff0046",
  "#ff0069",
  "#f3008a",
  "#df08a8",
  "#c137c3",
  "#9b4fd7",
  "#6660e4",
  "#0072f2",
  "#008aff",
  "#009eff",
  "#00affa",
  "#00bde0",
  "#00c9bc",
  "#00d392",
  "#00db66",
  "#47dc4c",
  "#6ed93b",
  "#8bd72c",
  "#a3d31e",
  "#b9d013",
  "#cdcc0e",
  "#dfc714",
  "#f0c220"
];

export { choiceChartColorArray, categoriesColorsArray };

// https://hue.tools/mix?format=hex&colors=fc111fff%3B2b69e9ff%3B0cdd57ff%3Bf0c220&steps=10&view=steps&mode=lch
