import { ChoiceBar } from "./ChoiceBar";
import { ChoiceGradBar } from "./ChoiceGradBar";
import { ChoicePie } from "./ChoicePie";

function DataStyle({ resultStyle, choice, chartColor, percentage, rot, children }) {
  switch (resultStyle) {
    case 0:
      return (
        <ChoiceBar
          choice={choice}
          chartColor={chartColor}
          percentage={percentage}
          children={children}
        />
      );
    case 1:
      return (
        <ChoiceGradBar
          choice={choice}
          chartColor={chartColor}
          percentage={percentage}
          children={children}
        />
      );
    default:
      return (
        <ChoicePie
          choice={choice}
          chartColor={chartColor}
          percentage={percentage}
          rot={rot}
          children={children}
        />
      );
  }
}

export { DataStyle };
