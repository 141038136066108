import React from "react";
import { Link } from "react-router-dom";
import { CookieConsent } from "react-cookie-consent";

import { OtcSymbol } from "../icons/otc_symbols/OtcSymbol";

function Footer() {
  // get current year
  const year = new Date().getFullYear();

  return (
    <>
      <CookieConsent
        overlayClasses="cookieConsent__overlay"
        containerClasses="cookieConsent__container"
        contentClasses="cookieConsent__content"
        buttonWrapperClasses="cookieConsent__button"
        sameSite="strict"
        disableStyles={true}
        buttonText="I Accept"
        overlay
      >
        <p>
          This website uses cookies to ensure you get the best experience on our website.{" "}
          <a href="/privacy">More info...</a>
        </p>
      </CookieConsent>
      <footer className="otc-footer">
        <div className="otc-footer-inner">
          <div className="otc-footer-copyright">
            <p>Copyright {year} © Chris Kew.</p>
          </div>
          <nav>
            <menu>
              <li>
                <Link to="/" className="link-home">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/poll/create" className="link-create">
                  Create Poll
                </Link>
              </li>
              <li>
                <Link to="/help" className="link-help">
                  Help
                </Link>
              </li>
            </menu>
            <menu className="legal">
              <li>
                <Link to="/privacy" className="link-privacy">
                  Privacy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="link-terms">
                  Terms
                </Link>
              </li>
              <li>
                <Link to="/cookies" className="link-cookies">
                  Cookies
                </Link>
              </li>
            </menu>
          </nav>
        </div>
      </footer>
      <OtcSymbol />
    </>
  );
}

export { Footer };
