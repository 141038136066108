import React, { useEffect } from "react";

export default function Privacy() {
  useEffect(() => {
    document.body.classList.add("page-privacy");

    return () => {
      document.body.classList.remove("page-privacy");
    };
  });

  return (
    <>
      <main>
        <div className="small-print">
          <section>
            <h1>PRIVACY NOTICE</h1>
            <p>Last updated October 05, 2022</p>
            <p>
              This privacy notice for Chris Kew ('<strong>Company</strong>', '<strong>we</strong>',
              '<strong>us</strong>', or '<strong>our</strong>'), describes how and why we might
              collect, store, use, and/or share ('<strong>process</strong>') your information when
              you use our services ('<strong>Services</strong>'), such as when you:
            </p>
            <ul>
              <li>
                Visit our website at https://offthecuffpolls.uk, or any website of ours that links
                to this privacy notice
              </li>
              <li>
                Engage with us in other related ways, including any sales, marketing, or events
              </li>
            </ul>
            <p>
              <strong>Questions or concerns?</strong> Reading this privacy notice will help you
              understand your privacy rights and choices. If you do not agree with our policies and
              practices, please do not use our Services. If you still have any questions or
              concerns, please contact us at info@outcan.uk.
            </p>
          </section>
          <section>
            <h2>SUMMARY OF KEY POINTS</h2>
            <p>
              <strong>
                <em>
                  This summary provides key points from our privacy notice, but you can find out
                  more details about any of these topics by clicking the link following each key
                  point or by using our table of contents below to find the section you are looking
                  for. You can also click <a href="#toc">here</a> to go directly to our table of
                  contents.
                </em>
              </strong>
            </p>
            <p>
              <strong>What personal information do we process?</strong> When you visit, use, or
              navigate our Services, we may process personal information depending on how you
              interact with Chris Kew and the Services, the choices you make, and the products and
              features you use. Click <a href="#personalinfo">here</a> to learn more.
            </p>
            <p>
              <strong>Do we process any sensitive personal information?</strong> We do not process
              sensitive personal information.
            </p>
            <p>
              <strong>Do we receive any information from third parties?</strong> We do not receive
              any information from third parties.
            </p>
            <p>
              <strong>How do we process your information?</strong> We process your information to
              provide, improve, and administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your information for
              other purposes with your consent. We process your information only when we have a
              valid legal reason to do so. Click <a href="#processinfo">here</a> to learn more.
            </p>
            <p>
              In what situations and with which parties do we share personal information? We may
              share information in specific situations and with specific third parties. Click{" "}
              <a href="#shareinfo">here</a> to learn more.
            </p>
            <p>
              How do we keep your information safe? We have organisational and technical processes
              and procedures in place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology can be guaranteed to
              be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
              other unauthorised third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information. Click{" "}
              <a href="#infosafe">here</a> to learn more.
            </p>
            <p>
              What are your rights? Depending on where you are located geographically, the
              applicable privacy law may mean you have certain rights regarding your personal
              information. Click <a href="#privacyrights">here</a> to learn more.
            </p>
            <p>
              How do you exercise your rights? The easiest way to exercise your rights is by filling
              out our data subject request form available here , or by contacting us. We will
              consider and act upon any request in accordance with applicable data protection laws.
            </p>
            <p>
              Want to learn more about what Chris Kew does with any information we collect? Click{" "}
              <a href="#toc">here</a> to review the notice in full.
            </p>
          </section>
          <section>
            <h2 className="anchor" id="toc">
              TABLE OF CONTENTS
            </h2>
            <ol>
              <li>
                <a href="#infocollect">WHAT INFORMATION DO WE COLLECT?</a>
              </li>
              <li>
                <a href="#processinfo">HOW DO WE PROCESS YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="#legalbases">
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
                </a>
              </li>
              <li>
                <a href="#shareinfo">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
              </li>
              <li>
                <a href="#cookies">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
              </li>
              <li>
                <a href="#keepinfo">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
              </li>
              <li>
                <a href="#infosafe">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
              </li>
              <li>
                <a href="#privacyrights">WHAT ARE YOUR PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a href="#donottrack">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
              </li>
              <li>
                <a href="#california">DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
              </li>
              <li>
                <a href="#updates">DO WE MAKE UPDATES TO THIS NOTICE?</a>
              </li>
              <li>
                <a href="#contactus">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
              </li>
              <li>
                <a href="#reviewupdate">
                  HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </a>
              </li>
            </ol>
          </section>
          <section>
            <h2 className="anchor" id="infocollect">
              1. WHAT INFORMATION DO WE COLLECT?
            </h2>
            <h3 className="anchor" id="personalinfo">
              Personal information you disclose to us
            </h3>
            <p>
              <em>
                <strong>In Short:</strong> We collect personal information that you provide to us.
              </em>
            </p>
            <p>
              We collect personal information that you voluntarily provide to us when you express an
              interest in obtaining information about us or our products and Services, when you
              participate in activities on the Services, or otherwise when you contact us.
            </p>
            <p>
              <strong>Sensitive Information.</strong> We do not process sensitive information.
            </p>
            <p>
              All personal information that you provide to us must be true, complete, and accurate,
              and you must notify us of any changes to such personal information.
            </p>
            <h3>Information automatically collected</h3>
            <p>
              <em>
                <strong>In Short:</strong> Some information — such as your Internet Protocol (IP)
                address and/or browser and device characteristics — is collected automatically when
                you visit our Services.
              </em>
            </p>
            <p>
              We automatically collect certain information when you visit, use, or navigate the
              Services. This information does not reveal your specific identity (like your name or
              contact information) but may include device and usage information, such as your IP
              address, browser and device characteristics, operating system, language preferences,
              referring URLs, device name, country, location, information about how and when you use
              our Services, and other technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our internal analytics
              and reporting purposes.
            </p>
            <p>
              Like many businesses, we also collect information through cookies and similar
              technologies.
            </p>
            <p>The information we collect includes:</p>
            <ul>
              <li>
                <em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic,
                usage, and performance information our servers automatically collect when you access
                or use our Services and which we record in log files. Depending on how you interact
                with us, this log data may include your IP address, device information, browser
                type, and settings and information about your activity in the Services (such as the
                date/time stamps associated with your usage, pages and files viewed, searches, and
                other actions you take such as which features you use), device event information
                (such as system activity, error reports (sometimes called 'crash dumps'), and
                hardware settings).
              </li>
            </ul>
          </section>
          <section>
            <h2 className="anchor" id="processinfo">
              2. HOW DO WE PROCESS YOUR INFORMATION?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> We process your information to provide, improve, and
                administer our Services, communicate with you, for security and fraud prevention,
                and to comply with law. We may also process your information for other purposes with
                your consent.
              </em>
            </p>
            <p>
              <strong>
                We process your personal information for a variety of reasons, depending on how you
                interact with our Services, including:
              </strong>
            </p>
            <ul>
              <li>
                <strong>To save or protect an individual's vital interest.</strong> We may process
                your information when necessary to save or protect an individual’s vital interest,
                such as to prevent harm.
              </li>
            </ul>
          </section>
          <section>
            <h2 className="anchor" id="legalbases">
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> We only process your personal information when we believe
                it is necessary and we have a valid legal reason (i.e. legal basis) to do so under
                applicable law, like with your consent, to comply with laws, to provide you with
                services to enter into or fulfil our contractual obligations, to protect your
                rights, or to fulfil our legitimate business interests.
              </em>
            </p>
            <p>
              <strong>
                <em>If you are located in the EU or UK, this section applies to you.</em>
              </strong>
            </p>
            <p>
              The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the
              valid legal bases we rely on in order to process your personal information. As such,
              we may rely on the following legal bases to process your personal information:
            </p>
            <ul>
              <li>
                <strong>Consent.</strong> We may process your information if you have given us
                permission (i.e. consent) to use your personal information for a specific purpose.
                You can withdraw your consent at any time. Click <a href="#withdraw">here</a> to
                learn more.
              </li>
              <li>
                <strong>Legal Obligations.</strong> We may process your information where we believe
                it is necessary for compliance with our legal obligations, such as to cooperate with
                a law enforcement body or regulatory agency, exercise or defend our legal rights, or
                disclose your information as evidence in litigation in which we are involved.
              </li>
              <li>
                <strong>Vital Interests.</strong> We may process your information where we believe
                it is necessary to protect your vital interests or the vital interests of a third
                party, such as situations involving potential threats to the safety of any person.
              </li>
            </ul>
            <p>
              <strong>
                <em>If you are located in Canada, this section applies to you.</em>
              </strong>
            </p>
            <p>
              We may process your information if you have given us specific permission (i.e. express
              consent) to use your personal information for a specific purpose, or in situations
              where your permission can be inferred (i.e. implied consent). You can withdraw your
              consent at any time. Click <a href="#withdraw">here</a> to learn more.
            </p>
            <p>
              In some exceptional cases, we may be legally permitted under applicable law to process
              your information without your consent, including, for example:
            </p>
            <ul>
              <li>
                If collection is clearly in the interests of an individual and consent cannot be
                obtained in a timely way
              </li>
              <li>For investigations and fraud detection and prevention</li>
              <li>For business transactions provided certain conditions are met</li>
              <li>
                If it is contained in a witness statement and the collection is necessary to assess,
                process, or settle an insurance claim
              </li>
              <li>
                For identifying injured, ill, or deceased persons and communicating with next of kin
              </li>
              <li>
                If we have reasonable grounds to believe an individual has been, is, or may be
                victim of financial abuse
              </li>
              <li>
                If it is reasonable to expect collection and use with consent would compromise the
                availability or the accuracy of the information and the collection is reasonable for
                purposes related to investigating a breach of an agreement or a contravention of the
                laws of Canada or a province
              </li>
              <li>
                If disclosure is required to comply with a subpoena, warrant, court order, or rules
                of the court relating to the production of records
              </li>
              <li>
                If it was produced by an individual in the course of their employment, business, or
                profession and the collection is consistent with the purposes for which the
                information was produced
              </li>
              <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
              <li>If the information is publicly available and is specified by the regulations</li>
            </ul>
          </section>
          <section>
            <h2 className="anchor" id="shareinfo">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> We may share information in specific situations described
                in this section and/or with the following third parties.
              </em>
            </p>
            <p>We may need to share your personal information in the following situations:</p>
            <ul>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business to another company.
              </li>
            </ul>
          </section>
          <section>
            <h2 className="anchor" id="cookies">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> We may use cookies and other tracking technologies to
                collect and store your information.
              </em>
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web beacons and pixels) to
              access or store information. Specific information about how we use such technologies
              and how you can refuse certain cookies is set out in our Cookie Notice.
            </p>
          </section>
          <section>
            <h2 className="anchor" id="keepinfo">
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> We keep your information for as long as necessary to
                fulfil the purposes outlined in this privacy notice unless otherwise required by
                law.
              </em>
            </p>
            <p>
              We will only keep your personal information for as long as it is necessary for the
              purposes set out in this privacy notice, unless a longer retention period is required
              or permitted by law (such as tax, accounting, or other legal requirements).
            </p>
            <p>
              When we have no ongoing legitimate business need to process your personal information,
              we will either delete or anonymise such information, or, if this is not possible (for
              example, because your personal information has been stored in backup archives), then
              we will securely store your personal information and isolate it from any further
              processing until deletion is possible.
            </p>
          </section>
          <section>
            <h2 className="anchor" id="infosafe">
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> We aim to protect your personal information through a
                system of organisational and technical security measures.
              </em>
            </p>
            <p>
              We have implemented appropriate and reasonable technical and organisational security
              measures designed to protect the security of any personal information we process.
              However, despite our safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology can be guaranteed to
              be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or
              other unauthorised third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information. Although we will do our
              best to protect your personal information, transmission of personal information to and
              from our Services is at your own risk. You should only access the Services within a
              secure environment.
            </p>
          </section>
          <section>
            <h2 className="anchor" id="privacyrights">
              8. WHAT ARE YOUR PRIVACY RIGHTS?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> In some regions, such as the European Economic Area
                (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater
                access to and control over your personal information. You may review, change, or
                terminate your account at any time.
              </em>
            </p>
            <p>
              In some regions (like the EEA, UK, and Canada), you have certain rights under
              applicable data protection laws. These may include the right (i) to request access and
              obtain a copy of your personal information, (ii) to request rectification or erasure;
              (iii) to restrict the processing of your personal information; and (iv) if applicable,
              to data portability. In certain circumstances, you may also have the right to object
              to the processing of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section{" "}
              <a href="#contactus">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below.
            </p>
            <p>
              We will consider and act upon any request in accordance with applicable data
              protection laws.
            </p>
            <p>
              If you are located in the EEA or UK and you believe we are unlawfully processing your
              personal information, you also have the right to complain to your local data
              protection supervisory authority. You can find their contact details here:{" "}
              <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              </a>
              .
            </p>
            <p>
              If you are located in Switzerland, the contact details for the data protection
              authorities are available here:{" "}
              <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                https://www.edoeb.admin.ch/edoeb/en/home.html
              </a>
              .
            </p>
            <p className="anchor" id="withdraw">
              <strong>Withdrawing your consent:</strong> If we are relying on your consent to
              process your personal information, which may be express and/or implied consent
              depending on the applicable law, you have the right to withdraw your consent at any
              time. You can withdraw your consent at any time by contacting us by using the contact
              details provided in the section{" "}
              <a href="#contactus">'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</a> below.
            </p>
            <p>
              However, please note that this will not affect the lawfulness of the processing before
              its withdrawal nor, when applicable law allows, will it affect the processing of your
              personal information conducted in reliance on lawful processing grounds other than
              consent.
            </p>
            <p>
              <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept
              cookies by default. If you prefer, you can usually choose to set your browser to
              remove cookies and to reject cookies. If you choose to remove cookies or reject
              cookies, this could affect certain features or services of our Services. To opt out of
              interest-based advertising by advertisers on our Services visit{" "}
              <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
            </p>
            <p>
              If you have questions or comments about your privacy rights, you may email us at
              info@outcan.uk.
            </p>
          </section>
          <section>
            <h2 className="anchor" id="donottrack">
              9. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h2>
            <p>
              Most web browsers and some mobile operating systems and mobile applications include a
              Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy
              preference not to have data about your online browsing activities monitored and
              collected. At this stage no uniform technology standard for recognising and
              implementing DNT signals has been finalised. As such, we do not currently respond to
              DNT browser signals or any other mechanism that automatically communicates your choice
              not to be tracked online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a revised version of
              this privacy notice.
            </p>
          </section>
          <section>
            <h2 className="anchor" id="california">
              10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> Yes, if you are a resident of California, you are granted
                specific rights regarding access to your personal information.
              </em>
            </p>
            <p>
              California Civil Code Section 1798.83, also known as the 'Shine The Light' law,
              permits our users who are California residents to request and obtain from us, once a
              year and free of charge, information about categories of personal information (if any)
              we disclosed to third parties for direct marketing purposes and the names and
              addresses of all third parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California resident and would like
              to make such a request, please submit your request in writing to us using the contact
              information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a registered account
              with Services, you have the right to request removal of unwanted data that you
              publicly post on the Services. To request removal of such data, please contact us
              using the contact information provided below and include the email address associated
              with your account and a statement that you reside in California. We will make sure the
              data is not publicly displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from all our systems (e.g. backups,
              etc.).
            </p>
          </section>
          <section>
            <h2 class="anchor" id="updates">
              11. DO WE MAKE UPDATES TO THIS NOTICE?
            </h2>
            <p>
              <em>
                <strong>In Short:</strong> Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
              </em>
            </p>
            <p>
              We may update this privacy notice from time to time. The updated version will be
              indicated by an updated 'Revised' date and the updated version will be effective as
              soon as it is accessible. If we make material changes to this privacy notice, we may
              notify you either by prominently posting a notice of such changes or by directly
              sending you a notification. We encourage you to review this privacy notice frequently
              to be informed of how we are protecting your information.
            </p>
          </section>
          <section>
            <h2 className="anchor" id="contactus">
              12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h2>
            <p>
              If you have questions or comments about this notice, you may email us at
              info@outcan.uk.
            </p>
          </section>
          <section>
            <h2 className="anchor" id="reviewupdate">
              13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
            </h2>
            <p>
              Based on the applicable laws of your country, you may have the right to request access
              to the personal information we collect from you, change that information, or delete
              it. To request to review, update, or delete your personal information, please contact
              us by email info@outcan.uk.
            </p>
            <p>
              This privacy policy was created using Termly's{" "}
              <a
                href="https://termly.io/products/privacy-policy-generator/"
                rel="noreferrer"
                target="_blank"
              >
                Privacy Policy Generator
              </a>
              .
            </p>
          </section>
        </div>
      </main>
    </>
  );
}
