import React from "react";

function SuccessIcon(props) {
  const title = props.title || "Success";
  const className = props.className || "icon-success";

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill="currentcolor">
        <path d="M11,23A11,11,0,0,1,11,1a10.832,10.832,0,0,1,5.83,1.652l-1.06,1.7A8.864,8.864,0,0,0,11,3a9,9,0,1,0,9,9,9.9,9.9,0,0,0-.27-2.358l1.94-.484A11.909,11.909,0,0,1,22,12,11.013,11.013,0,0,1,11,23Z" />
        <path d="M11,15a1,1,0,0,1-.707-.293L5.586,10,7,8.586l4,4,11-11L23.414,3,11.707,14.707A1,1,0,0,1,11,15Z" />
      </g>
    </svg>
  );
}

export default SuccessIcon;
