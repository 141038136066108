import React from "react";
import { add, format, isWithinInterval, formatDistanceToNow } from "date-fns";

function CreatedDate(props) {
  const { date } = props;
  const formattedDate = format(new Date(date), "dd MMMM yyyy");
  if (!props.addStatusText) {
    return <strong>{formattedDate}</strong>;
  }
  const futureDate = add(new Date(date), { weeks: 2, days: 1 });
  const formatTextDate = formatDistanceToNow(new Date(futureDate), {
    addSuffix: true
  });
  const statusText = (date, futureDate) => {
    if (
      isWithinInterval(new Date(), {
        start: new Date(date),
        end: new Date(futureDate)
      })
    ) {
      return ` and will expire ${formatTextDate}.`;
    } else {
      return ` and expired ${formatTextDate}.`;
    }
  };
  return (
    <>
      <strong>{formattedDate}</strong> {statusText(date, futureDate)}
    </>
  );
}

export { CreatedDate };
