import { Modal } from "./Modal";
import { CategoryBadge } from "../CategoryBadge";
import { ButtonGeneral } from "../ButtonGeneral";
import { BarChart } from "../BarChart";

function ModalChart({ title, poll, className, chartColors, closeModal }) {
  let data = {
    labels: poll?.choices?.map((choice) => choice.name),
    datasets: [
      {
        label: "Votes",
        data: poll?.choices?.map((choice) => choice.count),
        backgroundColor: [...chartColors[0]],
        borderColor: [...chartColors[1]],
        borderWidth: 2,
        borderRadius: 8
      }
    ]
  };

  let options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        min: 0,
        max: () => getMaxValue(poll?.choices),
        ticks: {
          stepSize: 1,
          color: "#000"
        }
      },
      X: {
        ticks: {
          color: "#000"
        }
      }
    },
    animation: {
      delay: 250
    }
  };

  function getMaxValue(array) {
    return Math.max(...array.map((choice) => choice.count)) + 1;
  }

  return (
    <Modal classname="chart-parent" category={poll?.category}>
      <div className={`modal__content ${className}`}>
        <CategoryBadge category={poll?.category} />
        <p className="modal__sub-head">{title}</p>
        <h1>{poll?.title}</h1>
        <div className="modal__chart">
          <div className="modal__chart-inner">
            <BarChart data={data} options={options} />
          </div>
        </div>
        <ButtonGeneral
          className="modal__close btn--pop-primary"
          action={closeModal}
          label="Close"
          icon="Close"
        />
      </div>
    </Modal>
  );
}

export { ModalChart };
