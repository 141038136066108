import React from "react";

function TickIcon(props) {
  const title = props.title || "Tick";
  const className = props.className || "icon-tick";

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g>
        <g
          id="Iconly/Broken/Tick-Square"
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <path
            id="Tick-Square"
            d="M13.753 0C17.59 0 20 2.392 20 6.253v2.569a.757.757 0 0 1-.756.756h-.009V9.56a.765.765 0 0 1-.765-.763V6.253c0-3.053-1.67-4.723-4.714-4.723h-7.5C3.21 1.53 1.53 3.21 1.53 6.253v7.5c0 3.034 1.68 4.714 4.723 4.714h7.5c3.043 0 4.714-1.68 4.714-4.714a.765.765 0 1 1 1.53 0C20 17.608 17.608 20 13.756 20H6.253C2.392 20 0 17.608 0 13.756v-7.5C0 2.392 2.392 0 6.253 0zm-.723 7.096a.75.75 0 1 1 1.06 1.062l-4.746 4.747a.754.754 0 0 1-1.061 0L5.91 10.53a.75.75 0 1 1 1.06-1.062l1.844 1.845z"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(2 2)"
          />
        </g>
      </g>
    </svg>
  );
}

export default TickIcon;
