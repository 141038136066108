import { ButtonGeneral } from "./ButtonGeneral";
import DeleteIcon from "../icons/DeleteIcon";

function PollChoice(props) {
  const { onChoiceChange, btnAction, index, option } = props;
  return (
    <div key={index} className="poll__choice form-group">
      <label htmlFor={`choice_${index}`}>* Poll choice</label>
      <input
        key={index}
        onChange={(event) => onChoiceChange(index, event.target.value, event.target.id)}
        value={option}
        type="text"
        id={`choice_${index}`}
        placeholder="Required"
      />
      <ButtonGeneral className="btn--pop-primary" label="Delete" action={() => btnAction(index)}>
        <DeleteIcon />
      </ButtonGeneral>
    </div>
  );
}

export { PollChoice };
