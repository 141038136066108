function ChoiceGradBar({ choice, chartColor, percentage, children }) {
  return (
    <div className="poll-card__choice choice-style-gradbar" id={choice._id}>
      <div className="choice-layout">
        <h2>{choice.name}</h2>
        <div className="percentage-bar">
          <h3>{percentage}%</h3>
          <div className="percentage-bar__outer">
            <div
              className="percentage-bar__inner"
              style={{
                width: `${percentage}%`,
                backgroundImage: `linear-gradient(180deg, transparent 0%, ${chartColor} 95%)`
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="poll-card__choice--count">
        {choice.count}
        {choice.count === 1 ? " vote" : " votes"}
      </div>
      {children}
    </div>
  );
}

export { ChoiceGradBar };
