import React from "react";

function ErrorIcon(props) {
  const title = props.title || "Error";
  const className = props.className || "icon-error";

  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill="currentColor">
        <g
          id="Iconly/Broken/Danger"
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
        >
          <path
            id="Danger"
            d="M8.63.354a2.832 2.832 0 0 1 3.847 1.106l7.272 12.726c.017.029.031.06.044.09.12.294.187.603.204.919a2.809 2.809 0 0 1-.725 2.04 2.805 2.805 0 0 1-1.956.932H6.56a.75.75 0 0 1 0-1.5h10.72c.315-.017.641-.171.88-.436a1.319 1.319 0 0 0 .26-1.35l-7.25-12.686a1.334 1.334 0 0 0-1.812-.528c-.219.12-.398.3-.519.519L1.583 14.882c-.117.318-.109.663.023.976.138.327.395.58.724.714.14.057.283.089.432.097a.75.75 0 0 1-.038 1.499l-.038-.001a2.806 2.806 0 0 1-.922-.205 2.811 2.811 0 0 1-1.539-1.52 2.805 2.805 0 0 1-.015-2.166.618.618 0 0 1 .043-.09L7.53 1.45A2.83 2.83 0 0 1 8.63.354zm1.371 12.16c.414 0 .75.33.75.745v.01a.75.75 0 0 1-1.5 0c0-.414.336-.755.75-.755zm0-6.243a.75.75 0 0 1 .75.75v2.827a.75.75 0 0 1-1.5 0V7.021a.75.75 0 0 1 .75-.75z"
            fill="currentColor"
            fillRule="nonzero"
            transform="translate(2 3)"
          />
        </g>
      </g>
    </svg>
  );
}

export default ErrorIcon;
