import { useRef } from "react";

import { Modal } from "./Modal";
import { CategoryBadge } from "../CategoryBadge";
import { ButtonGeneral } from "../ButtonGeneral";
import { QRCodeImage } from "../QRCodeImage";

function ModalShare({ title, poll, closeModal }) {
  const linkRef = useRef();

  function copyPollLinkToClipboard() {
    const el = document.createElement("textarea");
    el.value = linkRef.current.value;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  function handleClipboardCopy() {
    const copyLinkInput = document.querySelector("#copy-link-text");
    //const copyLinkInput = linkRef.current;
    //const copyLinkText = copyLinkInput.value;
    copyLinkInput.select();
    copyLinkInput.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyLinkInput.value);
  }

  const col = `${getComputedStyle(document.querySelector(".category-badge")).getPropertyValue(
    `--${poll?.category.toLowerCase()}`
  )}`;
  return (
    <Modal className="modal__share" category={poll?.category}>
      <div className="modal__content">
        <CategoryBadge category={poll.category} />
        <p className="modal__sub-head">{title}</p>
        <h1 className="modal__title">{poll.title}?</h1>
        <hr className="modal__hr" />
        <div className="modal__qr-code">
          <p className="modal__sub-head">Scan QR code</p>
          <div className="modal__qr-code-image">
            <QRCodeImage
              text={`${window.location.origin}/poll/${poll.slug}`}
              options={{
                margin: 4,
                scale: 4,
                width: 100,
                level: "M",
                color: {
                  dark: `${col.substring(1)}ff`
                }
              }}
            />
          </div>
        </div>
        <div className="modal__copy-link">
          <p className="modal__sub-head">Copy link</p>
          <div className="modal__copy-control">
            <input
              id="copy-link-text"
              ref={linkRef}
              type="text"
              value={`${window.location.origin}/poll/${poll.slug}`}
              readOnly
            />
            <ButtonGeneral
              className="share__copy-button btn--pop--category"
              style={{
                "--lightness": "98%",
                backgroundColor: `var(--${poll.category.toLowerCase()}-hsl)`
              }}
              action={
                window.location.protocol === "https:"
                  ? handleClipboardCopy
                  : copyPollLinkToClipboard
              }
              label="Copy"
              icon="Copy"
            >
              <span
                className="bot"
                style={{
                  backgroundColor: `var(--${poll.category.toLowerCase()}-hsl)`
                }}
              ></span>
              <span
                className="top"
                style={{
                  color: `var(--${poll.category.toLowerCase()})`,
                  backgroundColor: `var(--${poll.category.toLowerCase()})`
                }}
              ></span>
            </ButtonGeneral>
          </div>
        </div>
        <ButtonGeneral
          className="modal__close btn--pop-primary"
          action={closeModal}
          label="Close"
          icon="Close"
        />
      </div>
    </Modal>
  );
}

export { ModalShare };
