function Loader({ loaderText, width, height, color }) {
  return (
    <div className="loader">
      <p>{loaderText}</p>
      <div
        className="spinner"
        style={{
          width: `${width}`,
          height: `${height}`,
          borderTopColor: `${color}`
        }}
      ></div>
    </div>
  );
}

export { Loader };
