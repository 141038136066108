import ErrorIcon from "../icons/ErrorIcon";
import CloseIcon from "../icons/CloseIcon";

function AlertError({ errors, handleAlertClose }) {
  return (
    <div className="alert alert__error">
      <ErrorIcon className="alert__icon" />
      <div className="alert__content">
        <h2 className="alert__title">
          Sorry, the following {errors.length > 1 ? "errors" : "error"} occurred:
        </h2>
        {errors.map((error, index) => (
          <p key={index} className="error__item">
            {error}
          </p>
        ))}
      </div>
      <div className="close" onClick={handleAlertClose}>
        <CloseIcon />
      </div>
    </div>
  );
}

export { AlertError };
