import React from "react";

function VotedIndicator(props) {
  return (
    <div className="poll-card__voted">
      <svg className="icon icon-tick-round" viewBox="0 0 32 32">
        <use href="#icon-tick-round"></use>
      </svg>
    </div>
  );
}

export { VotedIndicator };
