import React, { useState, useRef, useEffect } from "react";
import ReCaptcha from "react-google-recaptcha";
import axios from "axios";

import PlusIcon from "../icons/PlusIcon";
import TickIcon from "../icons/TickIcon";
import {
  caseSentence,
  checkForDuplicates,
  checkInViewAndScroll,
  handleAllErrors,
  handleAlertClose
} from "../utils/utils";
import { AlertError } from "../components/AlertError";
import { Select } from "../components/Select";
import { ButtonGeneral } from "../components/ButtonGeneral";
import { PollChoice } from "../components/PollChoice";
import { PollQuestion } from "../components/PollQuestion";
import { SuccessPanel } from "../components/SuccessPanel";
import { Loader } from "../components/Loader";

export default function CreatePoll() {
  const pollContainer = useRef(null);
  const [title, setTitle] = useState("");
  const [options, setOptions] = useState(["", ""]);
  const [category, setCategory] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState({});
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [checked, setChecked] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef(null);

  const addOption = () => {
    setOptions([...options, ""]);
  };

  const onChoiceChange = (index, value) => {
    const newOptions = options.map((option, optionIdx) => {
      if (optionIdx === index) {
        return value;
      }
      return option;
    });
    setOptions(newOptions);
  };

  const removeChoice = (index) => {
    const newOptions = options.filter((option, optionIdx) => {
      return optionIdx !== index;
    });
    setOptions(newOptions);
  };

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setCategory(category);
  };

  const handleRecaptchaChange = () => {
    setRecaptchaToken(recaptchaRef.current.getValue());
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    document.body.classList.add("page-create");

    return () => {
      document.body.classList.remove("page-create");
    };
  });

  const createNewPoll = async () => {
    if (checkForErrors()) {
      return;
    }
    try {
      setLoading(true);
      const payload = {
        title: caseSentence(title),
        choices: options.map((option) => caseSentence(option)),
        category,
        checked,
        recaptchaToken
      };

      //const url = `${window.location.origin}/api/v1/polls/create`;
      const url = `/api/v1/polls/create`;
      const response = await axios(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(payload)
      });
      if (response.data.status === "success") {
        setTitle("");
        setOptions([""]);
        setCategory("");
        setSuccess(response.data);
        setErrors([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.data.statusCode === 409) {
          recaptchaRef.current.reset();
        }
        handleAllErrors(setErrors, error);
      } else {
        handleAllErrors(setErrors, error);
      }
      checkInViewAndScroll(".alert__error", pollContainer, 100);
    }
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    //const form = event.target;
    //const email = form.elements.email.value;
    const url = `${window.location.origin}/api/v1/poll/sendEmail`;
    const payload = {
      emailAddress: userEmail,
      _id: success.poll._id
    };
    sendData(url, payload);
    // try {
    //   const response = await axios(url, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json"
    //     },
    //     data: JSON.stringify({ email, _id: success._id })
    //   });
    // } catch (error) {
    //   if (error.response) {
    //     handleAllErrors(error);
    //   }
    // }
  };

  const sendData = async (url, payload) => {
    try {
      setLoading(true);
      const response = await axios(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(payload)
      });
      if (response.data.status === "success") {
        setSuccess(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        handleAllErrors(setErrors, error);
      } else {
        handleAllErrors(setErrors, error);
      }
    }
  };

  const checkForErrors = () => {
    let allErrors = [];
    if (errors.length > 0) {
      setErrors([]);
    }
    if (title === "") {
      allErrors = [...allErrors, "Poll question is required!"];
    }
    if (options.length < 2) {
      allErrors = [...allErrors, "At least two options for the poll are are required!"];
    }
    if (options.includes("")) {
      allErrors = [...allErrors, "Options cannot be empty!"];
    }
    if (checkForDuplicates(options)) {
      allErrors = [...allErrors, "Sorry, but you cannot have duplicate options!"];
    }
    if (category === "") {
      allErrors = [...allErrors, "Please select a category for the poll!"];
    }
    if (recaptchaToken === "") {
      allErrors = [...allErrors, "Please verify you are not a robot!"];
    }
    setErrors(allErrors);
    if (allErrors.length) {
      checkInViewAndScroll(".poll__status", pollContainer, 100);
      return allErrors;
    }
    return false;
  };

  const handleCreateNewPoll = () => {
    window.location.reload();
  };

  if (loading) {
    return (
      <Loader
        loaderText={
          <>
            Loading poll data...
            <br />
            ...counting pixels.
          </>
        }
        width="100px"
        height="100px"
        color="var(--brand-primary)"
      />
    );
  }

  return (
    <div
      className={
        success?.status === "success"
          ? "poll-container phase--success"
          : "poll-container phase--create"
      }
      ref={pollContainer}
    >
      <h1>Create new poll</h1>
      {/* Successful  creation of poll message */}
      {(success?.type === "create" || success?.type === "email") && (
        <ButtonGeneral
          className="btn--pop-primary btn--new-poll"
          label="Brand New Poll"
          action={handleCreateNewPoll}
        >
          <PlusIcon />
        </ButtonGeneral>
      )}
      {success?.status === "success" && (
        <SuccessPanel
          success={success}
          handleEmailSubmit={handleEmailSubmit}
          userEmail={userEmail}
          setUserEmail={setUserEmail}
        />
      )}
      <div className="poll__wrapper">
        {success?.type !== "email" && (
          <div className="poll__status">
            <h2 className={`status--${errors?.length ? "error" : "ok"}`}>
              {success?.status === "success" ? (
                <>
                  <span>Poll review – status:</span>{" "}
                  {errors?.length ? `Error` : "Poll created successfully"}
                </>
              ) : (
                <>
                  <span>Poll review – status:</span> {errors?.length ? `Error` : "OK"}
                </>
              )}
            </h2>
            {!errors?.length && (
              <>
                {title === "" ? "" : <p className="poll__status--question">{title}?</p>}
                {options?.length > 0 && options[0] !== "" && (
                  <ul>
                    {options?.map((option, index) => {
                      if (option === "") return null;
                      return (
                        <li key={index}>
                          <span>–</span> <span>{option}</span>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {category === "" ? null : <p>Category: {category}</p>}
              </>
            )}
            {/* Error message */}
            {errors?.length > 0 && (
              <AlertError errors={errors} handleAlertClose={() => handleAlertClose(setErrors)} />
            )}
          </div>
        )}
        {success?.status !== "success" && (
          <div className="poll__panel">
            <PollQuestion setTitle={setTitle} title={title} />
            <div className="poll__choices">
              {options?.map((option, index) => (
                <PollChoice
                  key={index}
                  index={index}
                  onChoiceChange={onChoiceChange}
                  btnAction={removeChoice}
                  option={option}
                />
              ))}
            </div>
            <div className="poll__option-add">
              <ButtonGeneral className="btn--pop-primary" action={addOption} label="Add Choice">
                <PlusIcon />
              </ButtonGeneral>
            </div>
            <div className="poll__category form-group">
              <Select onChange={handleCategoryChange} selectedCategory={category} />
            </div>
            <div className="poll__show-home form-group">
              <label className="form-checkbox" htmlFor="show-on-home">
                <input
                  type="checkbox"
                  id="show-on-home"
                  name="show-on-home"
                  defaultChecked={checked}
                  onChange={handleCheckboxChange}
                />
                Show on home page - visible to all visitors
              </label>
            </div>
            <ReCaptcha
              ref={recaptchaRef}
              sitekey={"6LfEMCgfAAAAAM7UOtk7Fnz9gDdQqkARRIzSwMo5"}
              className="poll__recaptcha"
              onChange={handleRecaptchaChange}
            />
            <div className="poll__create-poll">
              <ButtonGeneral
                className="btn--pop-primary btn--full"
                label="Create New Poll"
                action={createNewPoll}
              >
                <TickIcon />
              </ButtonGeneral>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
