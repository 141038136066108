import React, { useRef } from "react";
import { Link } from "react-router-dom";

import { MobileNavButton } from "./MobileNavButton";

import OTCLogoAlt from "./OTCLogoAlt";

function Header() {
  const mobileNavHolder = useRef(null);
  return (
    <header className="otc-header">
      <div className="nav-wrapper">
        <div className="brand">
          <Link to="/">
            <OTCLogoAlt />
          </Link>
        </div>
        <MobileNavButton element={mobileNavHolder} />
        <div className="mobile-nav-holder" ref={mobileNavHolder}>
          <nav className="main-nav">
            <menu>
              <li>
                <Link to="/" className="link-home">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/poll/create" className="link-create">
                  Create Poll
                </Link>
              </li>
              <li>
                <Link to="/help" className="link-help">
                  Help
                </Link>
              </li>
            </menu>
          </nav>
        </div>
      </div>
    </header>
  );
}

export { Header };
