function caseSentence([firstLetter, ...restOfString]) {
  return firstLetter.toUpperCase() + restOfString.join("");
}

function checkForDuplicates(array) {
  return new Set(array).size !== array.length;
}

// format date like 28 January 2020
function formatDate(date) {
  const d = new Date(date);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return `${d.getDate()} ${month[d.getMonth()]} ${d.getFullYear()}`;
}

function handleAllErrors(callback, error) {
  if (error?.response?.data?.status === "error") {
    if (error?.response?.data?.errors) {
      return callback(error?.response?.data?.errors.map((error) => error.msg));
    }
    return callback([error?.response?.data?.message]);
  }
  if (error?.response?.status === 500) {
    return callback(["An internal server error occurred, please try again later."]);
  }
  callback([error?.message || "Something went wrong, please try again later."]);
}

function handleAlertClose(callback) {
  callback([]);
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  const html = document.documentElement;
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || html.clientHeight) &&
    rect.right <= (window.innerWidth || html.clientWidth)
  );
}

function checkInViewAndScroll(elementToCheck, elementToScrollTo, offset) {
  let element = document.querySelector(elementToCheck);
  let scrollToElement =
    typeof elementToScrollTo.current !== "undefined"
      ? elementToScrollTo.current
      : elementToScrollTo;
  if (!isInViewport(element)) {
    window.scrollTo({
      top: scrollToElement.getBoundingClientRect().top - offset,
      behavior: "smooth"
    });
  }
}

export {
  caseSentence,
  checkForDuplicates,
  formatDate,
  handleAllErrors,
  handleAlertClose,
  isInViewport,
  checkInViewAndScroll
};
