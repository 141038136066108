import React from "react";

import SuccessIcon from "../icons/SuccessIcon";
import { QRCodeImage } from "./QRCodeImage";
import TickIcon from "../icons/TickIcon";

function SuccessPanel({ handleEmailSubmit, success, userEmail, setUserEmail }) {
  return (
    <div className="success__panel">
      <SuccessIcon className="icon-success hero" />
      <h2>{success.message}</h2>
      {success.type === "email" && (
        <div className="success__content">
          <p>A link to your poll has been sent to the email address you provided.</p>
          <p>
            Please check your email and click on the link to view your poll. You may have to check
            your spam folder.
          </p>
        </div>
      )}
      {success.type === "create" && (
        <>
          <div className="success__content">
            <p>
              To access your poll, please action one of the following. <span>Important:</span> you
              will not be shown them again or have access to them later.
            </p>
            <div className="success__link-qrcode">
              <div className="success__link">
                <p>Copy link</p>
                <p className="poll-link">
                  <a href={`${window.location.origin}/poll/${success.poll.slug}`}>
                    {`${window.location.origin}/poll/${success.poll.slug}`}
                  </a>
                </p>
              </div>
              <figure>
                <figcaption>Scan QR code</figcaption>
                <QRCodeImage
                  text={`${window.location.origin}/poll/${success.poll.slug}`}
                  options={{
                    margin: 4,
                    scale: 4,
                    width: 100,
                    color: { dark: "#0C641Eff" }
                  }}
                />
              </figure>
            </div>
          </div>
          <div className="success__email">
            <p>
              Or send the poll <span>access link to your email.</span>
            </p>
            <form onSubmit={handleEmailSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={(event) => setUserEmail(event.target.value)}
                  value={userEmail || ""}
                />
                <button className="btn--pop-success" type="submit">
                  <TickIcon /> Send
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export { SuccessPanel };
