import React, { useEffect } from "react";

export default function Cookies() {
  useEffect(() => {
    document.body.classList.add("page-cookies");

    return () => {
      document.body.classList.remove("page-cookies");
    };
  });

  return (
    <>
      <main>
        <div className="small-print">
          <section>
            <h1>COOKIE POLICY</h1>
            <p>Last updated October 05, 2022</p>
            <p>
              This Cookie Policy explains how Chris Kew ("<strong>Company</strong>", "
              <strong>we</strong>", "<strong>us</strong>", and "<strong>our</strong>") uses cookies
              and similar technologies to recognize you when you visit our websites at{" "}
              https://offthecuffpolls.uk, ("<strong>Websites</strong>"). It explains what these
              technologies are and why we use them, as well as your rights to control our use of
              them.
            </p>
            <p>
              In some cases we may use cookies to collect personal information, or that becomes
              personal information if we combine it with other information.
            </p>
            <p> We currently do not collect personal information on this website.</p>
          </section>
          <section>
            <h2>What are cookies?</h2>
            <p>
              Cookies are small data files that are placed on your computer or mobile device when
              you visit a website. Cookies are widely used by website owners in order to make their
              websites work, or to work more efficiently, as well as to provide reporting
              information.
            </p>
            <p>
              Cookies set by the website owner (in this case, Chris Kew) are called "first party
              cookies". Cookies set by parties other than the website owner are called "third party
              cookies". Third party cookies enable third party features or functionality to be
              provided on or through the website (e.g. like advertising, interactive content and
              analytics). The parties that set these third party cookies can recognize your computer
              both when it visits the website in question and also when it visits certain other
              websites.
            </p>
          </section>
          <section>
            <h2>Why do we use cookies?</h2>
            <p>
              We use first and third party cookies for several reasons. Some cookies are required
              for technical reasons in order for our Websites to operate, and we refer to these as
              "essential" or "strictly necessary" cookies. Other cookies also enable us to track and
              target the interests of our users to enhance the experience on our Online Properties.
              Third parties serve cookies through our Websites for advertising, analytics and other
              purposes. This is described in more detail below.
            </p>
            <p>
              The specific types of first and third party cookies served through our Websites and
              the purposes they perform are described below (please note that the specific cookies
              served may vary depending on the specific Online Properties you visit):
            </p>
          </section>
          <section>
            <h2>How can I control cookies?</h2>
            <p>
              You have the right to decide whether to accept or reject cookies. Only essential
              cookies are used on this website. To use this website you must accept our use of
              cookies by clicking the 'I Accept' button located at the bottom of the screen.
            </p>
            <p>
              The specific types of first and third party cookies served through our Websites and
              the purposes they perform are described in the table below (please note that the
              specific cookies served may vary depending on the specific Online Properties you
              visit):
            </p>
          </section>
          <section>
            <h2>Essential website cookies:</h2>
            <p>
              These cookies are strictly necessary to provide you with services available through
              our Websites and to use some of its features, such as voting on polls.
            </p>
            <p className="cookie-essential">
              <span>Name: CookieConsent</span>
              <span>Purpose: Record user consent for cookie usage on website</span>
              <span>Provider: *.offthecuffpolls.uk</span>
              <span>Country: United Kingdom</span>
              <span>Type: http_cookie</span>
              <span>Expires in: 1 year</span>
            </p>
            <p>We also use cookies to prevent duplicate votes being cast.</p>
          </section>
          <section>
            <h2>What about other tracking technologies, like web beacons?</h2>
            <p>
              Cookies are not the only way to recognize or track visitors to a website. We may use
              other, similar technologies from time to time, like web beacons (sometimes called
              "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a
              unique identifier that enable us to recognize when someone has visited our Websites or
              opened an e-mail including them . This allows us, for example, to monitor the traffic
              patterns of users from one page within a website to another, to deliver or communicate
              with cookies, to understand whether you have come to the website from an online
              advertisement displayed on a third-party website, to improve site performance, and to
              measure the success of e-mail marketing campaigns. In many instances, these
              technologies are reliant on cookies to function properly, and so declining cookies
              will impair their functioning.
            </p>
          </section>
          <section>
            <h2>Do you use Flash cookies or Local Shared Objects?</h2>
            <p>
              Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or
              "LSOs") to, among other things, collect and store information about your use of our
              services, fraud prevention and for other site operations.
            </p>
            <p>We do not use Flash cookies or Local Shared Objects.</p>
          </section>
          <section>
            <h2>Do you serve targeted advertising?</h2>
            <p>We do not currently serve targeted advertising.</p>
          </section>
          <section>
            <h2>How often will you update this Cookie Policy?</h2>
            <p>
              We may update this Cookie Policy from time to time in order to reflect, for example,
              changes to the cookies we use or for other operational, legal or regulatory reasons.
              Please therefore re-visit this Cookie Policy regularly to stay informed about our use
              of cookies and related technologies.
            </p>
            <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>
          </section>
          <section>
            <h2>Where can I get further information?</h2>
            <p>
              If you have any questions about our use of cookies or other technologies, please email
              us at info@outcan.uk.
            </p>
            <p>
              This cookie policy was created using Termly's{" "}
              <a
                href="https://termly.io/products/cookie-consent-manager/"
                rel="noreferrer"
                target="_blank"
              >
                Cookie Consent Manager
              </a>
              .
            </p>
          </section>
        </div>
      </main>
    </>
  );
}
