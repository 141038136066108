function PollQuestion(props) {
  const { setTitle, title } = props;

  return (
    <div className="poll__title form-group">
      <label htmlFor="title">* Poll question</label>
      <input
        onChange={(event) => setTitle(event.target.value)}
        value={title || ""}
        type="text"
        id="title"
        name="title"
        placeholder="Required"
      />
    </div>
  );
}

export { PollQuestion };
