function VotedFor({ category }) {
  return (
    <div className="voted-for" data-category={category}>
      <div className="voted-for__inner">
        <svg className="icon" viewBox="0 0 24 24">
          <use href="#icon-Tick"></use>
        </svg>
        You voted
      </div>
    </div>
  );
}

export { VotedFor };
