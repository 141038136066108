import React from "react";

function OtcSymbol() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
      <symbol id="icon-View" viewBox="0 0 24 24">
        <title>View</title>
        <g className="nc-icon-wrapper">
          <g id="Iconly/Broken/View" stroke="none" fill="none" fillRule="evenodd">
            <path
              d="M10.002 0c4.136.002 7.851 2.886 9.938 7.714.08.183.08.39 0 .573a16.897 16.897 0 0 1-.822 1.654.733.733 0 0 1-.997.277.726.726 0 0 1-.278-.99c.222-.391.432-.804.625-1.228C16.601 3.896 13.453 1.456 10 1.455 6.546 1.456 3.398 3.896 1.531 8c1.867 4.104 5.015 6.545 8.47 6.545 2.007 0 3.944-.831 5.601-2.404a.736.736 0 0 1 1.034.024c.28.29.268.75-.024 1.027C14.679 15.028 12.394 16 10.002 16c-4.14-.002-7.855-2.885-9.943-7.713a.723.723 0 0 1 0-.573C2.147 2.886 5.862.002 9.997 0zM10 4.124c2.15 0 3.899 1.739 3.899 3.876 0 2.138-1.75 3.876-3.9 3.876-.707 0-1.4-.191-2.003-.552a.723.723 0 0 1-.25-.998.732.732 0 0 1 1.003-.247 2.44 2.44 0 0 0 1.25.343A2.432 2.432 0 0 0 12.437 8 2.431 2.431 0 0 0 10 5.58 2.431 2.431 0 0 0 7.564 8a.73.73 0 0 1-.732.727A.729.729 0 0 1 6.1 8C6.1 5.863 7.85 4.124 10 4.124z"
              id="View"
              transform="translate(2 4)"
              fill="currentColor"
              fillRule="nonzero"
              stroke="none"
            ></path>
          </g>
        </g>
      </symbol>
      <symbol id="icon-Help" viewBox="0 0 32 32">
        <title>Help</title>
        <g className="nc-icon-wrapper">
          <path
            fill="currentColor"
            d="M27.3,4.7c-3-3-7-4.7-11.3-4.7S7.7,1.7,4.7,4.7c-3,3-4.7,7-4.7,11.3s1.7,8.3,4.7,11.3c3,3,7,4.7,11.3,4.7 s8.3-1.7,11.3-4.7c3-3,4.7-7,4.7-11.3S30.3,7.7,27.3,4.7z M28.2,22.9l-4.5-4.5c0.5-1.5,0.5-3.2,0-4.7l4.5-4.5 c1.2,2.1,1.8,4.4,1.8,6.9S29.4,20.8,28.2,22.9z M18.3,21.6C18.3,21.6,18.3,21.6,18.3,21.6C17.5,21.8,16.8,22,16,22s-1.5-0.2-2.3-0.4 c0,0,0,0,0,0c-0.7-0.3-1.4-0.7-2-1.3c-2.3-2.3-2.3-6.1,0-8.5c0.6-0.6,1.3-1,2-1.3c0,0,0,0,0,0c0.7-0.3,1.5-0.4,2.3-0.4 c0.8,0,1.5,0.2,2.3,0.4c0,0,0,0,0,0c0.7,0.3,1.4,0.7,2,1.3c2.3,2.3,2.3,6.1,0,8.5C19.7,20.8,19,21.3,18.3,21.6z M22.9,3.8l-4.5,4.5 C17.6,8.1,16.8,8,16,8c-0.8,0-1.6,0.1-2.4,0.4L9.1,3.8C11.2,2.6,13.5,2,16,2C18.5,2,20.8,2.6,22.9,3.8z M3.8,9.1l4.5,4.5 c-0.5,1.5-0.5,3.2,0,4.7l-4.5,4.5C2.6,20.8,2,18.5,2,16S2.6,11.2,3.8,9.1z M9.1,28.2l4.5-4.5c0.8,0.2,1.6,0.4,2.4,0.4 s1.6-0.1,2.4-0.4l4.5,4.5C20.8,29.4,18.5,30,16,30S11.2,29.4,9.1,28.2z"
            stroke="none"
          />
        </g>
      </symbol>
      <symbol id="icon-Error" viewBox="0 0 32 32">
        <title>Error</title>
        <g fillRule="evenodd" className="nc-icon-wrapper">
          <path
            d="m19 16 6-6M25 16l-6-6M7 10l6 6M13 10l-6 6"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5"
          />
          <path
            d="M10.427 25.13A5.973 5.973 0 0 1 16 21.32a5.973 5.973 0 0 1 5.573 3.81"
            fill="none"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="butt"
            strokeMiterlimit="1.5"
          />
          <circle cx="16" cy="16" r="14.75" fill="none" stroke="currentColor" strokeWidth="2.59" />
        </g>
      </symbol>
      <symbol id="icon-Question" viewBox="0 0 32 32">
        <title>Question</title>
        <g fill="currentColor">
          <circle cx="11" cy="17" r="4" fill="currentColor" />
          <path
            d="M27,15V27H18v-.982a3.014,3.014,0,0,0-1.474-2.585A10.764,10.764,0,0,0,11,22a10.7,10.7,0,0,0-5.538,1.429A3.017,3.017,0,0,0,4,26.007V27H2V8H14V6H1A1,1,0,0,0,0,7V28a1,1,0,0,0,1,1H28a1,1,0,0,0,1-1V15Z"
            fill="currentColor"
          />
          <path d="M20,16V13H18a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2H30a2,2,0,0,1,2,2v9a2,2,0,0,1-2,2H25Z" />
        </g>
      </symbol>
      <symbol id="icon-Copy" viewBox="0 0 24 24">
        <title>Copy</title>
        <g
          strokeLinecap="square"
          strokeWidth="2"
          fill="none"
          stroke="currentColor"
          className="nc-icon-wrapper"
          strokeMiterlimit="10"
        >
          <path d="M9.684 20.965A7.989 7.989 0 0 0 20.965 9.684" />
          <circle cx="9" cy="9" r="8" />
        </g>
      </symbol>
      <symbol id="icon-Share" viewBox="0 0 24 24">
        <title>Share</title>
        <g fillRule="evenodd" className="nc-icon-wrapper">
          <path
            d="M17.286 8.602c2.323 0 4.214 1.938 4.214 4.321v4.747c0 2.387-1.895 4.33-4.225 4.33a.74.74 0 0 1-.73-.75.74.74 0 0 1 .73-.75c1.524 0 2.762-1.27 2.762-2.83v-4.747c0-1.556-1.234-2.821-2.751-2.821h-.894a.741.741 0 0 1-.732-.75c0-.414.328-.75.732-.75zm-9.677 0a.74.74 0 0 1 .73.75c0 .413-.327.75-.73.75h-.884c-1.524 0-2.762 1.27-2.762 2.83v4.746c0 1.557 1.235 2.822 2.751 2.822h7.235c.404 0 .731.336.731.75s-.327.75-.731.75H6.714C4.391 22 2.5 20.062 2.5 17.678v-4.745C2.5 10.544 4.395 8.6 6.725 8.6zm4.112-6.546a.72.72 0 0 1 .798.163l2.764 2.846a.763.763 0 0 1-.002 1.06.718.718 0 0 1-1.035-.001l-1.514-1.56v9.886c0 .414-.328.75-.732.75a.743.743 0 0 1-.732-.75V2.75a.75.75 0 0 1 .452-.694zM9.409 4.35a.718.718 0 0 1 1.034-.006.76.76 0 0 1 .006 1.061l-.693.718a.718.718 0 0 1-1.033.005.761.761 0 0 1-.006-1.06z"
            fill="currentColor"
            stroke="none"
          />
        </g>
      </symbol>
      <symbol id="icon-Tick" viewBox="0 0 24 24">
        <title>Tick</title>
        <g className="nc-icon-wrapper">
          <polyline fill="none" stroke="currentColor" points="6,12 10,16 18,8 "></polyline>
        </g>
      </symbol>
      <symbol id="icon-Layout-Grid" viewBox="0 0 32 32">
        <title>Layout - Grid</title>
        <g fillRule="evenodd" className="nc-icon-wrapper">
          <path
            d="M31 26.532v1.42A3.048 3.048 0 0 1 27.952 31h-6.904A3.048 3.048 0 0 1 18 27.952v-6.904A3.048 3.048 0 0 1 21.048 18h6.904A3.048 3.048 0 0 1 31 21.048v1.42h-2v-1.42c0-.579-.469-1.048-1.048-1.048h-6.904c-.579 0-1.048.469-1.048 1.048v6.904c0 .579.469 1.048 1.048 1.048h6.904c.579 0 1.048-.469 1.048-1.048v-1.42h2zm-17-5.484A3.048 3.048 0 0 0 10.952 18H4.048A3.048 3.048 0 0 0 1 21.048v6.904A3.048 3.048 0 0 0 4.048 31h6.904A3.048 3.048 0 0 0 14 27.952v-6.904zm-2 0v6.904c0 .579-.469 1.048-1.048 1.048H4.048A1.048 1.048 0 0 1 3 27.952v-6.904C3 20.469 3.469 20 4.048 20h6.904c.579 0 1.048.469 1.048 1.048zM1 5.468v-1.42A3.048 3.048 0 0 1 4.048 1h6.904A3.048 3.048 0 0 1 14 4.048v6.904A3.048 3.048 0 0 1 10.952 14H4.048A3.048 3.048 0 0 1 1 10.952v-1.42h2v1.42C3 11.531 3.469 12 4.048 12h6.904c.579 0 1.048-.469 1.048-1.048V4.048C12 3.469 11.531 3 10.952 3H4.048C3.469 3 3 3.469 3 4.048v1.42H1zm30-1.42A3.048 3.048 0 0 0 27.952 1h-6.904A3.048 3.048 0 0 0 18 4.048v6.904A3.048 3.048 0 0 0 21.048 14h6.904A3.048 3.048 0 0 0 31 10.952V4.048zm-2 0v6.904c0 .579-.469 1.048-1.048 1.048h-6.904A1.048 1.048 0 0 1 20 10.952V4.048C20 3.469 20.469 3 21.048 3h6.904C28.531 3 29 3.469 29 4.048z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Layout-Stack" viewBox="0 0 32 32">
        <title>Layout - Stack</title>
        <g fillRule="evenodd" className="nc-icon-wrapper">
          <path
            d="M1 22.468v-1.42A3.046 3.046 0 0 1 4.048 18h23.904A3.048 3.048 0 0 1 31 21.048v6.904A3.048 3.048 0 0 1 27.952 31H4.048A3.047 3.047 0 0 1 1 27.952v-1.42h2v1.42A1.047 1.047 0 0 0 4.048 29h23.904c.579 0 1.048-.469 1.048-1.048v-6.904c0-.579-.469-1.048-1.048-1.048H4.048A1.047 1.047 0 0 0 3 21.048v1.42H1zM31 9.532v1.42A3.048 3.048 0 0 1 27.952 14H4.048A3.047 3.047 0 0 1 1 10.952V4.048A3.046 3.046 0 0 1 4.048 1h23.904A3.048 3.048 0 0 1 31 4.048v1.42h-2v-1.42C29 3.469 28.531 3 27.952 3H4.048A1.047 1.047 0 0 0 3 4.048v6.904A1.047 1.047 0 0 0 4.048 12h23.904c.579 0 1.048-.469 1.048-1.048v-1.42h2z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Close" viewBox="0 0 24 24">
        <title>Close</title>
        <g className="nc-icon-wrapper">
          <g id="Iconly/Broken/Close-Square" stroke="none" fill="none" fillRule="evenodd">
            <path
              d="M13.753 0C17.59 0 20 2.392 20 6.253v2.569a.757.757 0 0 1-.756.756h-.009V9.56a.765.765 0 0 1-.765-.763V6.253c0-3.053-1.67-4.723-4.714-4.723h-7.5C3.21 1.53 1.53 3.21 1.53 6.253v7.5c0 3.034 1.68 4.714 4.723 4.714h7.5c3.043 0 4.714-1.68 4.714-4.714a.765.765 0 1 1 1.53 0C20 17.608 17.608 20 13.756 20H6.253C2.392 20 0 17.608 0 13.756v-7.5C0 2.392 2.392 0 6.253 0zM7.815 11.145a.766.766 0 0 1 1.044 1.12l-.681.68a.764.764 0 0 1-1.063-.018l-.022-.016a.773.773 0 0 1 .038-1.082zm-.573-4.254a.755.755 0 0 1 1.077-.006l1.865 1.866 1.697-1.698a.783.783 0 0 1 1.088.007c.054.054.1.115.135.183a.767.767 0 0 1-.122.925l-1.69 1.69 1.838 1.838a.77.77 0 0 1 .028 1.091c-.004.006-.01.01-.015.016a.767.767 0 0 1-1.07.012l-.038-.037L7.255 8a.817.817 0 0 1-.013-1.108z"
              id="Close-Square"
              transform="translate(2 2)"
              fill="currentColor"
              fillRule="nonzero"
              stroke="none"
            ></path>
          </g>
        </g>
      </symbol>
      <symbol id="icon-OTC" viewBox="0 0 24 24">
        <title>OTC Info</title>
        <g fillRule="evenodd" className="nc-icon-wrapper">
          <path
            d="M9.926 21.443h9.542a3.565 3.565 0 0 0 3.53-3.029l.961-6.25a3.575 3.575 0 0 0-3.532-4.115h-5.804V3.585c0-1.97-.709-3.572-2.679-3.572a.896.896 0 0 0-.862.658l-2.055 9.32-5.034-.017H.998c-.547 0-.998.451-.998.998v12.031c0 .548.451.998.998.998 0 0 4.965.031 7.034.031.498 0 .991-.243 1.344-.596.352-.352.55-.83.55-1.328v-.665zm-5.933-10.53h5.012v11.195a.957.957 0 0 1-.958.957H3.993V10.913zm2.442 9.275a.922.922 0 1 1-.002 1.844.922.922 0 0 1 .002-1.844z"
            fillRule="nonzero"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Info" viewBox="0 0 24 24">
        <title>Info</title>
        <g className="nc-icon-wrapper">
          <g id="Iconly/Broken/Info-Square" stroke="none" fill="none" fillRule="evenodd">
            <path
              d="M13.747 0C17.608 0 20 2.393 20 6.256v7.5C20 17.608 17.608 20 13.747 20H6.244C2.392 20 0 17.608.001 13.753a.765.765 0 1 1 1.53 0c0 3.034 1.671 4.714 4.723 4.714h7.493c3.043 0 4.723-1.671 4.723-4.714v-7.5c0-3.043-1.67-4.723-4.723-4.723H6.253C3.21 1.53 1.53 3.21 1.53 6.253v2.331a.756.756 0 0 1-.756.756H.763A.763.763 0 0 1 0 8.575V6.253C0 2.393 2.41 0 6.254 0zM9.995 8.626a.75.75 0 0 1 .75.75v4.42a.75.75 0 0 1-1.5 0v-4.42a.75.75 0 0 1 .75-.75zm.01-3.172a.75.75 0 0 1 0 1.5h-.01a.746.746 0 0 1-.745-.75c0-.414.34-.75.755-.75z"
              id="Info-Square"
              transform="translate(2 2)"
              fill="currentColor"
              fillRule="nonzero"
              stroke="none"
            ></path>
          </g>
        </g>
      </symbol>
      <symbol id="icon-Style" viewBox="0 0 24 24">
        <title>Style</title>
        <g fillRule="evenodd" className="nc-icon-wrapper">
          <path
            d="M0 11.807v1.998A3.196 3.196 0 0 0 3.195 17h8.61A3.196 3.196 0 0 0 15 13.805V3.195A3.196 3.196 0 0 0 11.805 0h-8.61A3.196 3.196 0 0 0 0 3.195v2.612a1.001 1.001 0 0 0 2 0V3.195A1.195 1.195 0 0 1 3.195 2h8.61A1.195 1.195 0 0 1 13 3.195v10.61A1.195 1.195 0 0 1 11.805 15h-8.61A1.195 1.195 0 0 1 2 13.805v-1.999a1 1 0 0 0-2 .001z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M7.941 20.433a3.199 3.199 0 0 0 1.941 1.489l7.35 1.969a3.194 3.194 0 0 0 3.913-2.259l2.746-10.248a3.196 3.196 0 0 0-2.259-3.914l-3.267-.875a1 1 0 0 0-.518 1.932l3.267.875a1.198 1.198 0 0 1 .845 1.464l-2.746 10.248a1.196 1.196 0 0 1-1.464.845l-7.35-1.969a1.195 1.195 0 0 1-.725-.557 1 1 0 0 0-1.733 1z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-tick-round" viewBox="0 0 32 32">
        <title>Voted True</title>
        <g fillRule="evenodd" className="nc-icon-wrapper">
          <circle cx="16" cy="16" r="16" fill="currentColor" stroke="none"></circle>
          <path
            d="M12.511 23.457l-6.295-6.295 1.645-1.644 4.65 4.651L24.14 8.542l1.644 1.644L12.51 23.457z"
            fill="#fff"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Sort-Asc">
        <title>Sort - Ascending</title>
        <g className="nc-icon-wrapper">
          <g id="Iconly/Broken/Sort-Ascending" stroke="none" fill="none" fillRule="evenodd">
            <path
              d="M8.822 0c.417 0 .756.339.756.756v.009h-.017a.765.765 0 0 1-.764.765H6.253C3.2 1.53 1.53 3.2 1.53 6.244v7.5c0 3.046 1.68 4.726 4.723 4.726h7.5c3.034 0 4.714-1.68 4.714-4.723v-7.5c0-3.043-1.68-4.714-4.714-4.714a.766.766 0 0 1 0-1.53C17.608 0 20 2.392 20 6.244v7.503C20 17.608 17.608 20 13.756 20h-7.5C2.393 20 0 17.608 0 13.747v-7.5C0 2.41 2.393 0 6.253 0zM10 5.164a.75.75 0 0 1 .75.75v8.172a.756.756 0 0 1-.22.531c-.008.008-.017.01-.024.017a.782.782 0 0 1-.22.144.754.754 0 0 1-.25.051c-.014.001-.023.007-.036.007a.743.743 0 0 1-.53-.22L5.72 10.85a.749.749 0 1 1 1.062-1.058L9.25 12.27V5.914a.75.75 0 0 1 .75-.75zm3.217 4.629a.749.749 0 1 1 1.062 1.059l-1.278 1.284a.751.751 0 0 1-1.062-1.06z"
              id="Sort-Ascending"
              transform="rotate(-180 11 11)"
              fill="currentColor"
              fillRule="nonzero"
              stroke="none"
            ></path>
          </g>
        </g>
      </symbol>
      <symbol id="icon-Sort-Desc">
        <title>Sort - Descending</title>
        <g className="nc-icon-wrapper">
          <g id="Iconly/Broken/Sort-Descending" stroke="none" fill="none" fillRule="evenodd">
            <path
              d="M8.822 0c.417 0 .756.339.756.756v.009h-.017a.765.765 0 0 1-.764.765H6.253C3.2 1.53 1.53 3.2 1.53 6.244v7.5c0 3.046 1.68 4.726 4.723 4.726h7.5c3.034 0 4.714-1.68 4.714-4.723v-7.5c0-3.043-1.68-4.714-4.714-4.714a.766.766 0 0 1 0-1.53C17.608 0 20 2.392 20 6.244v7.503C20 17.608 17.608 20 13.756 20h-7.5C2.393 20 0 17.608 0 13.747v-7.5C0 2.41 2.393 0 6.253 0zM10 5.164a.75.75 0 0 1 .75.75v8.172a.756.756 0 0 1-.22.531c-.008.008-.017.01-.024.017a.782.782 0 0 1-.22.144.754.754 0 0 1-.25.051c-.014.001-.023.007-.036.007a.743.743 0 0 1-.53-.22L5.72 10.85a.749.749 0 1 1 1.062-1.058L9.25 12.27V5.914a.75.75 0 0 1 .75-.75zm3.217 4.629a.749.749 0 1 1 1.062 1.059l-1.278 1.284a.751.751 0 0 1-1.062-1.06z"
              id="Sort-Descending"
              transform="translate(2 2)"
              fill="currentColor"
              fillRule="nonzero"
              stroke="none"
            ></path>
          </g>
        </g>
      </symbol>
      <symbol id="icon-Chart">
        <title>Chart</title>
        <g className="nc-icon-wrapper">
          <g id="Iconly/Broken/Chart" stroke="none" fill="none" fillRule="evenodd">
            <path
              d="M13.747 0C17.59 0 20 2.392 20 6.253v2.322l-.007.104a.765.765 0 0 1-.758.661h-.009l-.12-.01a.756.756 0 0 1-.636-.764V6.253c0-3.043-1.68-4.723-4.723-4.723H6.253c-3.052 0-4.723 1.68-4.723 4.723v7.502c0 3.044 1.68 4.715 4.723 4.715h7.494c3.052 0 4.723-1.68 4.723-4.715a.765.765 0 0 1 1.53 0C20 17.608 17.608 20 13.755 20H6.253C2.393 20 0 17.608 0 13.755V6.253C0 2.393 2.392 0 6.253 0zM5.752 7.555a.765.765 0 0 1 .739.792v6.394a.766.766 0 0 1-1.53-.053V8.285l.013-.12a.756.756 0 0 1 .778-.61zm4.283-3c.423 0 .765.343.765.766v9.376a.765.765 0 1 1-1.53 0V5.32c0-.423.343-.765.765-.765zm4.24 6.377c.422 0 .765.343.765.765v2.99a.765.765 0 1 1-1.53 0v-2.99c0-.422.342-.765.764-.765z"
              id="Chart"
              transform="translate(2 2)"
              fill="currentColor"
              fillRule="nonzero"
              stroke="none"
            ></path>
          </g>
        </g>
      </symbol>
      <symbol id="icon-Technology">
        <title>Technology</title>
        <g className="nc-icon-wrapper">
          <path
            d="M1,17.728a1,1,0,0,1-1-1v-7.5a1,1,0,0,1,.293-.707L8.475.339A1,1,0,0,1,9.182.046H14a1,1,0,1,1,0,2H9.6L2,9.642v7.086A1,1,0,0,1,1,17.728Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M23,32.046H18a1,1,0,0,1,0-2h4.586L30,22.632V15.046a1,1,0,0,1,2,0v8a1,1,0,0,1-.293.707l-8,8A1,1,0,0,1,23,32.046Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M16,19.046a3,3,0,1,1,3-3A3,3,0,0,1,16,19.046Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M12,4.046a3,3,0,0,0-3,3,2.967,2.967,0,0,0,.315,1.311L4.3,13.336a1,1,0,0,0-.3.71v6H1a1,1,0,0,0,0,2H5a1,1,0,0,0,1-1V14.463l4.741-4.7A2.969,2.969,0,0,0,12,10.046a3,3,0,1,0,0-6Z"
            stroke="none"
          ></path>
          <path
            d="M14.707,25.339,10,20.632V16.046a1,1,0,0,0-2,0v5a1,1,0,0,0,.293.707L13,26.46v3.586H9.414L7.7,28.331A2.951,2.951,0,0,0,8,27.046a3,3,0,1,0-3,3,2.965,2.965,0,0,0,1.285-.3l2.008,2.008A1,1,0,0,0,9,32.046h5a1,1,0,0,0,1-1v-5A1,1,0,0,0,14.707,25.339Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M27,2.046a2.964,2.964,0,0,0-1.285.3L23.707.339A1,1,0,0,0,23,.046H18a1,1,0,0,0-1,1v6a1,1,0,0,0,.375.781L22,11.526v4.52a1,1,0,0,0,2,0v-5a1,1,0,0,0-.375-.781L19,6.565V2.046h3.586L24.3,3.76A2.969,2.969,0,0,0,24,5.046a3,3,0,1,0,3-3Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M31,10.046H27a1,1,0,0,0-1,1v6.586l-4.715,4.714a2.968,2.968,0,0,0-1.285-.3,3,3,0,1,0,3,3,2.969,2.969,0,0,0-.3-1.286l5.008-5.007A1,1,0,0,0,28,18.046v-6h3a1,1,0,0,0,0-2Z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Music">
        <title>Music</title>
        <g className="nc-icon-wrapper">
          <path
            fill="currentColor"
            d="M30,1H10C9.448,1,9,1.448,9,2v19.261C8.048,20.474,6.828,20,5.5,20C2.467,20,0,22.467,0,25.5 S2.467,31,5.5,31s5.5-2.467,5.5-5.5V10h18v8.261C28.048,17.474,26.828,17,25.5,17c-3.033,0-5.5,2.467-5.5,5.5s2.467,5.5,5.5,5.5 s5.5-2.467,5.5-5.5V2C31,1.448,30.552,1,30,1z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Programming" viewBox="0 0 32 32">
        <title>Programming</title>
        <g className="nc-icon-wrapper">
          <path fill="currentColor" d="M32,6V3c0-0.552-0.447-1-1-1H7v4H32z" stroke="none"></path>
          <path fill="currentColor" d="M5,2H1C0.447,2,0,2.448,0,3v3h5V2z" stroke="none"></path>
          <path
            fill="currentColor"
            d="M0,8v21c0,0.552,0.447,1,1,1h30c0.553,0,1-0.448,1-1V8H0z M6,23.414L4.586,22l4-4l-4-4L6,12.586L11.414,18 L6,23.414z M20,23h-8v-2h8V23z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Social">
        <title>Social</title>
        <g className="nc-icon-wrapper">
          <path
            fill="currentColor"
            d="M31,0H14c-0.552,0-1,0.448-1,1v16.081L19,12h12c0.552,0,1-0.448,1-1V1 C32,0.448,31.552,0,31,0z"
            stroke="none"
          ></path>
          <circle fill="currentColor" cx="25" cy="18" r="4" stroke="none"></circle>
          <circle fill="currentColor" cx="7" cy="18" r="4" stroke="none"></circle>
          <path
            fill="currentColor"
            d="M12.525,25.433C11.421,24.779,9.565,24,7,24c-2.6,0-4.446,0.776-5.538,1.429C0.561,25.968,0,26.955,0,28.007 V31h14v-2.982C14,26.96,13.435,25.97,12.525,25.433z"
            stroke="none"
          ></path>
          <path
            fill="currentColor"
            d="M30.525,25.433C29.421,24.779,27.565,24,25,24c-2.6,0-4.446,0.776-5.538,1.429 C18.561,25.968,18,26.955,18,28.007V31h14v-2.982C32,26.96,31.435,25.97,30.525,25.433z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Politics">
        <title>Politics</title>
        <g className="nc-icon-wrapper">
          <rect x="3" y="16" width="2" height="10" stroke="none"></rect>
          <rect x="11" y="16" width="2" height="10" stroke="none"></rect>
          <rect x="19" y="16" width="2" height="10" stroke="none"></rect>
          <rect x="27" y="16" width="2" height="10" stroke="none"></rect>
          <path
            fill="currentColor"
            d="M31,28H1c-0.552,0-1,0.447-1,1v2c0,0.553,0.448,1,1,1h30c0.552,0,1-0.447,1-1v-2C32,28.447,31.552,28,31,28z "
            stroke="none"
          ></path>
          <path
            fill="currentColor"
            d="M31.471,8.117l-15-8c-0.294-0.156-0.647-0.156-0.941,0l-15,8C0.204,8.291,0,8.631,0,9v4c0,0.553,0.448,1,1,1 h30c0.552,0,1-0.447,1-1V9C32,8.631,31.796,8.291,31.471,8.117z M16,10c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2 c1.105,0,2,0.895,2,2C18,9.105,17.105,10,16,10z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Design">
        <title>Design</title>
        <g className="nc-icon-wrapper">
          <path
            fill="currentColor"
            d="M26,0h-4v11h-2V0h-8v11h-2V0H6C5.447,0,5,0.448,5,1v19c0,0.249,0.093,0.489,0.26,0.673l10,11 C15.449,31.881,15.718,32,16,32s0.551-0.119,0.74-0.327l10-11C26.907,20.489,27,20.249,27,20V1C27,0.448,26.553,0,26,0z M19.194,26 h-6.388l-5.35-5.885L11,17.281l4.375,3.5c0.365,0.292,0.885,0.292,1.25,0l4.375-3.5l3.543,2.835L19.194,26z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-General">
        <title>General</title>
        <g className="nc-icon-wrapper">
          <path
            d="M31.144,11.643,21.3,10.212l-4.4-8.92a1.041,1.041,0,0,0-1.794,0l-4.4,8.92L.856,11.643A1,1,0,0,0,.3,13.348l7.124,6.944L5.744,30.1A1,1,0,0,0,7.195,31.15L16,26.521l8.805,4.629A1,1,0,0,0,26.256,30.1l-1.682-9.8L31.7,13.348a1,1,0,0,0-.555-1.705Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Books">
        <title>Book</title>
        <g className="nc-icon-wrapper">
          <path
            fill="currentColor"
            d="M28,0H7C4.794,0,3,1.794,3,4v24c0,2.206,1.794,4,4,4h21c0.553,0,1-0.448,1-1v-6V1C29,0.448,28.553,0,28,0z M10,8h13c0.552,0,1,0.448,1,1v5c0,0.552-0.448,1-1,1H10c-0.552,0-1-0.448-1-1V9C9,8.448,9.448,8,10,8z M27,30H7 c-1.103,0-2-0.897-2-2s0.897-2,2-2h20V30z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Drink">
        <title>Drink</title>
        <g className="nc-icon-wrapper">
          <path
            d="M24,0a7.006,7.006,0,0,0-6.92,6H3a1,1,0,0,0-.814,1.581L12,21.321V30H7a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2H14V21.321l9.814-13.74A1,1,0,0,0,23,6H19.1A5,5,0,1,1,24,12a1,1,0,0,0,0,2A7,7,0,0,0,24,0ZM18.2,12H7.8L4.943,8H21.057Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Health">
        <title>Health</title>
        <g className="nc-icon-wrapper">
          <path
            d="M18.7,23.062a1,1,0,0,1-1.632-.046l-4.938-7.408L10.99,17.514a1,1,0,0,1-.857.486H3.956C8.326,24.634,16,30.964,16,30.964S23.674,24.634,28.044,18H22.5Z"
            stroke="none"
          ></path>
          <path
            d="M30.18,14A10.12,10.12,0,0,0,31,10.315,8.169,8.169,0,0,0,16,5.727,8.3,8.3,0,0,0,9.2,2.036,8.248,8.248,0,0,0,1,10.315,10.123,10.123,0,0,0,1.821,14H0v2H9.566l2.479-4.131,5.907,8.861L21.5,16H32V14Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Food">
        <title>Food</title>
        <g className="nc-icon-wrapper">
          <path
            fill="currentColor"
            d="M5.6,22.2c3,2.1,7.3,2.8,10.4,2.8c3.7,0,7.8-1.1,10.4-2.8l0.3-0.2L16.9,2.5c-0.3-0.7-1.4-0.7-1.8,0L5.2,21.9 L5.6,22.2z M22,19c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3S22,17.3,22,19z M16,9c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S14.9,9,16,9z M12.5,15c0.8,0,1.5,0.7,1.5,1.5S13.3,18,12.5,18S11,17.3,11,16.5S11.7,15,12.5,15z"
            stroke="none"
          ></path>
          <path
            d="M29.7,27.7l-2-4l-0.1,0.1C24.6,25.8,20.1,27,16,27c-3.4,0-8.2-0.8-11.6-3.2l-0.1-0.1l-2,4 c-0.1,0.2-0.1,0.5-0.1,0.8c0.1,0.3,0.3,0.5,0.5,0.6C6.9,31,11.4,32,16,32s9.1-1,13.2-2.9c0.2-0.1,0.4-0.3,0.5-0.6 C29.9,28.3,29.8,28,29.7,27.7z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Movies">
        <title>Movies</title>
        <g className="nc-icon-wrapper">
          <path
            d="M31,30H23.715A16.189,16.189,0,0,0,27.3,27.3,15.981,15.981,0,1,0,4.7,4.7,16,16,0,0,0,16,32H31a1,1,0,0,0,0-2ZM24,13a3,3,0,1,1-3,3A2.946,2.946,0,0,1,24,13ZM16,5a2.946,2.946,0,0,1,3,3,3,3,0,0,1-6,0A2.946,2.946,0,0,1,16,5ZM8,19a3,3,0,0,1,0-6,3,3,0,0,1,0,6Zm5,5a3,3,0,1,1,3,3A2.946,2.946,0,0,1,13,24Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Sport">
        <title>Sport</title>
        <g className="nc-icon-wrapper">
          <path d="M6.5,32A3.5,3.5,0,1,1,10,28.5,3.5,3.5,0,0,1,6.5,32Z" stroke="none"></path>
          <circle cx="19" cy="3.5" r="3.5" fill="currentColor" stroke="none"></circle>
          <path
            d="M29.666,14.942l-8.9-5.888a1.465,1.465,0,0,0-.325-.154l-5.47-1.823a1.5,1.5,0,0,0-1.066.044l-10.4,4.73.984,2.3,7.94-3.188L8.577,22.525l2.682.9,1.3-3.364,1.8.6L11.551,30.684l2.372.791,6.4-19.192,8.013,4.775Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-History">
        <title>History</title>
        <g className="nc-icon-wrapper">
          <path d="M27,27H5a2,2,0,0,0-2,2v3H29V29A2,2,0,0,0,27,27Z" stroke="none"></path>
          <path
            d="M3.5,12c.037,0,.071-.009.107-.011L7.643,25H24.357l4.036-13.011c.036,0,.07.011.107.011A2.5,2.5,0,1,0,26,9.5a2.467,2.467,0,0,0,.319,1.2l-5.835,5.477L17.247,5.654a2.5,2.5,0,1,0-2.494,0l-3.237,10.52L5.681,10.7A2.467,2.467,0,0,0,6,9.5,2.5,2.5,0,1,0,3.5,12Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Pets">
        <title>Pets</title>
        <g className="nc-icon-wrapper">
          <path
            d="M19.092,11.164A4,4,0,0,1,16.646,12H5A3,3,0,0,1,2,9,1,1,0,0,0,0,9a4.99,4.99,0,0,0,4.578,4.957A3.961,3.961,0,0,0,4,16V31H6l.969-5.813a.987.987,0,0,1,.129-.35L10,20l10.354,2.824a1,1,0,0,1,.729.84L22,31h2V23l2.085-8.339Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M25,5,24,3,20.356,9.56l6.226,3.113L27,11h2a3,3,0,0,0,3-3V7Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M18,10H17A9.01,9.01,0,0,1,8,1V0h2V1a7.008,7.008,0,0,0,7,7h1Z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Wellness">
        <title>Wellness</title>
        <g className="nc-icon-wrapper">
          <rect x="20" y="8" width="3" height="2" fill="currentColor" stroke="none"></rect>
          <rect x="20" y="15" width="3" height="2" fill="currentColor" stroke="none"></rect>
          <rect x="7" y="8" width="10" height="2" fill="currentColor" stroke="none"></rect>
          <rect x="7" y="15" width="10" height="2" fill="currentColor" stroke="none"></rect>
          <rect x="7" y="22" width="10" height="2" fill="currentColor" stroke="none"></rect>
          <path
            d="M17,32H3a1,1,0,0,1-1-1V1A1,1,0,0,1,3,0H27a1,1,0,0,1,1,1V12H26V2H4V30H17Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M25.5,31.418l1.409.45a2.581,2.581,0,0,0,3.233-1.55l1.621-4.669a4.375,4.375,0,0,0-5.471-5.483l-.792.217-.792-.217a4.375,4.375,0,0,0-5.471,5.483l1.621,4.669a2.581,2.581,0,0,0,3.233,1.55Z"
            stroke="none"
          ></path>
          <path d="M30,14h0v1a4,4,0,0,1-4,4h0V18A4,4,0,0,1,30,14Z" stroke="none"></path>
        </g>
      </symbol>
      <symbol id="icon-Gaming">
        <title>Gaming</title>
        <g className="nc-icon-wrapper">
          <path
            fill="currentColor"
            d="M23,12H9c-4.962,0-9,4.038-9,9s4.038,9,9,9h14c4.962,0,9-4.038,9-9S27.962,12,23,12z M13,22h-3v3H8v-3H5v-2 h3v-3h2v3h3V22z M21.5,24c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5 C23,23.328,22.328,24,21.5,24z M25.5,20c-0.828,0-1.5-0.672-1.5-1.5c0-0.828,0.672-1.5,1.5-1.5c0.828,0,1.5,0.672,1.5,1.5 C27,19.328,26.328,20,25.5,20z"
            stroke="none"
          ></path>
          <path d="M17,3c0-0.552-0.448-1-1-1s-1,0.448-1,1v7h2V3z" stroke="none"></path>
        </g>
      </symbol>
      <symbol id="icon-Colour">
        <title>Colour</title>
        <g className="nc-icon-wrapper">
          <path
            fill="currentColor"
            d="M27.5,7.3c-1.4-0.7-2.9-1-4.5-1c-2.1,0-4.1,0.6-5.8,1.1c-1.2,0.4-2.3,0.7-3.1,0.7c-0.6,0-1-0.2-1.3-0.6 c-0.2-0.2,0-0.9,0.2-1.6c0.3-1.1,0.8-2.6-0.2-3.9c-0.6-0.8-1.6-1.2-2.8-1.2c-1.1,0-2.2,0.4-3.2,1C2.6,4.9,0,9.8,0,15 c0,8.9,7.2,16.1,16.1,16.1c7.2,0,13.5-4.8,15.5-11.7C31.8,18.5,33.6,10.3,27.5,7.3z M5,14c0-1.1,0.9-2,2-2s2,0.9,2,2 c0,1.1-0.9,2-2,2S5,15.1,5,14z M9,23c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C11,22.1,10.1,23,9,23z M16,26c-1.1,0-2-0.9-2-2 c0-1.1,0.9-2,2-2s2,0.9,2,2C18,25.1,17.1,26,16,26z M23,18c-2.2,0-4-1.8-4-4s1.8-4,4-4s4,1.8,4,4S25.2,18,23,18z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Entertainment">
        <title>Entertainment</title>
        <g className="nc-icon-wrapper">
          <path
            d="M21.333,1.771a31.158,31.158,0,0,0-20.666,0A1,1,0,0,0,0,2.714V15a11,11,0,0,0,22,0V2.714A1,1,0,0,0,21.333,1.771ZM4,10.5A1.5,1.5,0,0,1,5.5,9h2a1.5,1.5,0,0,1,0,3h-2A1.5,1.5,0,0,1,4,10.5ZM11,22a4,4,0,0,1-4-4h8A4,4,0,0,1,11,22Zm5.5-10h-2a1.5,1.5,0,0,1,0-3h2a1.5,1.5,0,0,1,0,3Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M31.333,7.771A30.867,30.867,0,0,0,24,6.165V15c0,.031,0,.062,0,.093A1.488,1.488,0,0,1,24.5,15h2a1.5,1.5,0,0,1,0,3h-2a1.486,1.486,0,0,1-.8-.237,12.985,12.985,0,0,1-3.412,6.309A3.961,3.961,0,0,1,25,28H17a3.959,3.959,0,0,1,.387-1.691,12.9,12.9,0,0,1-4.932,1.6A10.992,10.992,0,0,0,32,21V8.714A1,1,0,0,0,31.333,7.771Z"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Science">
        <title>Science</title>
        <g className="nc-icon-wrapper">
          <circle cx="17" cy="13" r="2" stroke="none"></circle>
          <path
            d="M16.346.016A13.249,13.249,0,0,0,4,13.377v.406l-2.41,5.3a1,1,0,0,0,.4,1.272L4,21.566V25a4,4,0,0,0,4,4h4v3H26V22.362A12.988,12.988,0,0,0,16.346.016ZM24,13.5a.5.5,0,0,1-.438.5l-1.727.216a4.964,4.964,0,0,1-.561,1.348l1.071,1.376a.5.5,0,0,1-.041.66L21.6,18.3a.5.5,0,0,1-.354.146.5.5,0,0,1-.306-.105L19.56,17.274a4.964,4.964,0,0,1-1.348.561L18,19.562a.5.5,0,0,1-.5.438h-1a.5.5,0,0,1-.5-.438l-.216-1.727a4.964,4.964,0,0,1-1.348-.561l-1.376,1.071a.5.5,0,0,1-.306.105A.5.5,0,0,1,12.4,18.3L11.7,17.6a.5.5,0,0,1-.041-.66l1.071-1.376a4.964,4.964,0,0,1-.561-1.348L10.438,14A.5.5,0,0,1,10,13.5v-1a.5.5,0,0,1,.438-.5l1.727-.216a4.964,4.964,0,0,1,.561-1.348L11.655,9.064A.5.5,0,0,1,11.7,8.4L12.4,7.7a.5.5,0,0,1,.66-.041L14.44,8.726a4.964,4.964,0,0,1,1.348-.561L16,6.438A.5.5,0,0,1,16.5,6h1a.5.5,0,0,1,.5.438l.216,1.727a4.964,4.964,0,0,1,1.348.561l1.376-1.071a.5.5,0,0,1,.66.041L22.3,8.4a.5.5,0,0,1,.041.66L21.274,10.44a4.964,4.964,0,0,1,.561,1.348L23.562,12a.5.5,0,0,1,.438.5Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Art">
        <title>Art</title>
        <g className="nc-icon-wrapper">
          <rect x="9" y="4" width="14" height="8" stroke="none"></rect>
          <path
            d="M26,0H6A1,1,0,0,0,5,1V15a1,1,0,0,0,1,1H26a1,1,0,0,0,1-1V1A1,1,0,0,0,26,0ZM25,14H7V2H25Z"
            stroke="none"
          ></path>
          <path d="M2,20a1,1,0,0,0-2,0V31a1,1,0,0,0,2,0Z" fill="currentColor" stroke="none"></path>
          <path
            d="M31,19a1,1,0,0,0-1,1v1.436a12.3,12.3,0,0,1-6,1.583,12.113,12.113,0,0,1-5-1.084v2.171a14.159,14.159,0,0,0,5,.913,14.3,14.3,0,0,0,6-1.335V31a1,1,0,0,0,2,0V20A1,1,0,0,0,31,19Z"
            fill="currentColor"
            stroke="none"
          ></path>
          <path
            d="M17,20a1,1,0,0,0-2,0v.81a12.194,12.194,0,0,1-7,2.209,12.154,12.154,0,0,1-4-.707v2.105a14,14,0,0,0,11-1.239V31a1,1,0,0,0,2,0Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Nature">
        <title>Nature</title>
        <g className="nc-icon-wrapper">
          <path
            d="M15.684,8.059A6.873,6.873,0,0,0,16,6c0-3.309-2.243-6-5-6S6,2.691,6,6a6.873,6.873,0,0,0,.316,2.059A8.451,8.451,0,0,0,4,14a7.944,7.944,0,0,0,5,7.663V28h2V15a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1v6.663A7.944,7.944,0,0,0,18,14,8.451,8.451,0,0,0,15.684,8.059Z"
            stroke="none"
          ></path>
          <path
            d="M25.684,8.059A6.873,6.873,0,0,0,26,6c0-3.309-2.243-6-5-6a4.722,4.722,0,0,0-3.852,2.179,8.949,8.949,0,0,1,.719,5.348A10.54,10.54,0,0,1,20,14a10.562,10.562,0,0,1-2.494,6.915A6.426,6.426,0,0,0,20,21.909V28h2V21.909c3.387-.558,6-3.887,6-7.909A8.451,8.451,0,0,0,25.684,8.059Z"
            stroke="none"
          ></path>
          <path
            d="M31.625,21.372a25.435,25.435,0,0,0-3.073-2.1,10.068,10.068,0,0,1-1.178,1.631A23.349,23.349,0,0,1,30,22.639V30H2V22.639a23.156,23.156,0,0,1,2.615-1.727,9.775,9.775,0,0,1-1.19-1.623,25.292,25.292,0,0,0-3.05,2.083,1,1,0,0,0-.375.78V31a1,1,0,0,0,1,1H31a1,1,0,0,0,1-1V22.152A1,1,0,0,0,31.625,21.372Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
      <symbol id="icon-Products">
        <title>Products</title>
        <g className="nc-icon-wrapper">
          <rect x="8" y="2" fill="currentColor" width="5" height="23" stroke="none"></rect>
          <rect x="23" y="2" fill="currentColor" width="5" height="23" stroke="none"></rect>
          <rect y="2" fill="currentColor" width="2" height="28" stroke="none"></rect>
          <rect x="4" y="2" fill="currentColor" width="2" height="23" stroke="none"></rect>
          <rect x="15" y="2" fill="currentColor" width="2" height="23" stroke="none"></rect>
          <rect x="30" y="2" fill="currentColor" width="2" height="28" stroke="none"></rect>
          <rect x="19" y="2" fill="currentColor" width="2" height="23" stroke="none"></rect>
        </g>
      </symbol>
      <symbol id="icon-News">
        <title>News</title>
        <g className="nc-icon-wrapper">
          <path
            d="M24,8a3.876,3.876,0,0,0-.573.058A17.361,17.361,0,0,0,23,12a17.361,17.361,0,0,0,.427,3.942A3.876,3.876,0,0,0,24,16a4,4,0,0,0,0-8Z"
            stroke="none"
          ></path>
          <path
            d="M15.371,22.941,7.453,20.309l3.949,9.71A3.173,3.173,0,0,0,14.344,32a3.139,3.139,0,0,0,1.175-.228,3.172,3.172,0,0,0,1.754-4.117Z"
            stroke="none"
          ></path>
          <path
            d="M25.5,0a3.831,3.831,0,0,0-1.4.274l0-.008L3.761,7.029A4.933,4.933,0,0,0,0,11.958a4.935,4.935,0,0,0,3.684,4.991L24.1,23.734l0-.008A3.831,3.831,0,0,0,25.5,24c3.645,0,6.5-5.271,6.5-12S29.145,0,25.5,0Zm0,22C23.372,22,21,17.893,21,12S23.372,2,25.5,2,30,6.107,30,12,27.628,22,25.5,22Z"
            fill="currentColor"
            stroke="none"
          ></path>
        </g>
      </symbol>
    </svg>
  );
}

export { OtcSymbol };
