import React from "react";

function PollLink({ slug, status, hasVoted, svgId }) {
  const actionStatus = hasVoted || status === "expired" ? "View" : "Vote";

  return (
    <a
      className="poll-card__link"
      href={`/poll/${slug}`}
      data-status={status}
      data-hasvoted={hasVoted}
      data-action={actionStatus.toLowerCase()}
    >
      <svg viewBox="0 0 24 24">
        <use href={`#icon-${svgId}`}></use>
      </svg>
      {actionStatus}
    </a>
  );
}

export { PollLink };
