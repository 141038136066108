function ChoicePie({ choice, chartColor, percentage, rot, children }) {
  return (
    <div className="poll-card__choice choice-style-pie" id={choice._id}>
      <div className="choice-layout">
        <div className="choice-text">
          <h2>{choice.name}</h2>
          <div className="poll-card__choice--count">
            {choice.count}
            {choice?.count === 1 ? " vote" : " votes"}
          </div>
        </div>
        <div className="pie">
          <div className="inner-ring"></div>
          <span>{percentage}%</span>
          <svg viewBox="0 0 32 32">
            <title>{percentage}%</title>
            <circle
              stroke={percentage !== 0 ? chartColor : null}
              r="16"
              cx="16"
              cy="16"
              strokeDasharray={`${percentage} ${percentage === 100 ? 0 : 100}`}
              transform={`rotate(${rot}, 16, 16)`}
            ></circle>
          </svg>
        </div>
      </div>
      {children}
    </div>
  );
}

export { ChoicePie };
