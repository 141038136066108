import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import { CategoryBadge } from "../components/CategoryBadge";
import { PollTitle } from "../components/PollTitle";
import { TotalVotes } from "../components/TotalVotes";
import { PollLink } from "../components/PollLink";
import { VotedIndicator } from "../components/VotedIndicator";
import { Loader } from "../components/Loader";
import { AlertError } from "../components/AlertError";
import { checkInViewAndScroll, handleAllErrors, handleAlertClose } from "../utils/utils";

export default function Home() {
  const htmlMain = useRef(null);
  const [polls, setPolls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    document.body.classList.add("page-home");

    return () => {
      document.body.classList.remove("page-home");
    };
  });

  useEffect(() => {
    const getPolls = async () => {
      try {
        const url = "/api/v1/polls";
        const response = await axios(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (response?.data?.status === "success") {
          const { polls } = response?.data;
          setLoading(false);
          setPolls([...polls]);
        }
        //const data = await response.json();
      } catch (error) {
        console.log("Error in getPolls", error);
        setLoading(false);
        if (error.response) {
          handleAllErrors(setErrors, error);
        } else {
          handleAllErrors(setErrors, error);
        }
        checkInViewAndScroll(".alert__error", htmlMain, 100);
      }
    };
    getPolls();
  }, []);

  if (loading) {
    return (
      <Loader
        loaderText={
          <>
            Loading data...
            <br />
            ...counting pixels.
          </>
        }
        width="100px"
        height="100px"
        color="var(--brand-primary)"
      />
    );
  }

  return (
    <>
      <h1>Latest Polls</h1>
      <main ref={htmlMain}>
        {errors?.length > 0 && (
          <AlertError errors={errors} handleAlertClose={() => handleAlertClose(setErrors)} />
        )}
        <div className="polls-container">
          {polls &&
            polls?.map((poll) => (
              <div key={poll?._id} className="poll-card trapezium">
                <CategoryBadge category={poll?.category} />
                <PollTitle Tag="h2" title={poll?.title}>
                  <TotalVotes
                    totalVotes={poll?.choices?.reduce((acc, choice) => acc + choice.count, 0)}
                  />
                </PollTitle>
                <PollLink
                  slug={poll?.slug}
                  status={poll?.documentStatus}
                  hasVoted={poll?.hasVoted}
                  svgId={poll?.hasVoted || poll?.documentStatus === "expired" ? "View" : "OTC"}
                />
                {poll?.hasVoted && <VotedIndicator />}
              </div>
            ))}
        </div>
      </main>
      {/*{<p style={{ color: "#fff" }}>{JSON.stringify(polls, null, 2)}</p>}*/}
    </>
  );
}
