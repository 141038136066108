const categories = [
  "Art",
  "Books",
  "Colour",
  "Design",
  "Drink",
  "Entertainment",
  "Food",
  "Gaming",
  "General",
  "Health",
  "History",
  "Movies",
  "Music",
  "Nature",
  "News",
  "Pets",
  "Politics",
  "Products",
  "Programming",
  "Question",
  "Science",
  "Social",
  "Sports",
  "Technology",
  "Wellness"
];

export { categories };
