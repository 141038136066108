import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { ActionButton } from "../components/ActionButton";
import { PollTitle } from "../components/PollTitle";
import { TotalVotes } from "../components/TotalVotes";
import { CategoryBadge } from "../components/CategoryBadge";
import { ModalService } from "../components/Modal/ModalService";
import { ModalInfo } from "../components/Modal/ModalInfo";
import { VotePanel } from "../components/VotePanel";
import { ModalChart } from "../components/Modal/ModalChart";
import { ModalShare } from "../components/Modal/ModalShare";
import { DataStyle } from "../components/DataStyle";
import { VotedFor } from "../components/VotedFor";
import { AlertError } from "../components/AlertError";
import { AlertSuccess } from "../components/AlertSuccess";
import { Loader } from "../components/Loader";

import { choiceChartColorArray } from "../utils/colorsArray";
import { handleAllErrors, handleAlertClose, checkInViewAndScroll } from "../utils/utils";

export default function ViewPoll() {
  const { slug } = useParams();
  const [poll, setPoll] = useState(null);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState({});

  const [resultStyle, setResultStyle] = useState(0);
  const [gridLayout, setGridLayout] = useState(false);
  const [sortOrder, setSortOrder] = useState(false);
  const [chartColors, setChartColors] = useState([...choiceChartColorArray]);
  const [loading, setLoading] = useState(true);
  const pollContent = useRef(null);
  const htmlMain = useRef(null);

  let rots = [];

  useEffect(() => {
    document.body.classList.add("page-poll");
    return () => document.body.classList.remove("page-poll");
  });

  useEffect(() => {
    async function getPoll() {
      try {
        const url = `/api/v1/poll/${slug}`;
        const response = await axios(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (response?.data?.status === "success") {
          const { poll } = response?.data;
          setPoll(poll);
          setChartColors((prevState) => {
            return [
              prevState[0].slice(0, poll?.choices.length),
              prevState[1].slice(0, poll?.choices.length)
            ];
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.data.errorType.toLowerCase() === "not-found") {
            window.location.replace(`${window.location.origin}/PageNotFound`);
          }
          handleAllErrors(setErrors, error);
        }
        handleAllErrors(setErrors, error);
        checkInViewAndScroll(".alert__error", htmlMain, 100);
      }
    }

    getPoll();
  }, [slug]);

  const vote = async (choice) => {
    const payload = {
      _id: poll?._id,
      choice
    };
    try {
      const url = `/api/v1/polls/${slug}/vote`;
      const response = await axios(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(payload)
      });
      if (response?.data?.status === "success") {
        const { pollDoc: poll } = response.data;
        // setPoll with new data
        setPoll(poll);
        setSuccess({
          status: response?.data?.status,
          message: response?.data?.message,
          choice: poll?.votedFor
        });
        checkInViewAndScroll(".alert__success", htmlMain, 100);
      }
    } catch (error) {
      if (error.response) {
        handleAllErrors(setErrors, error);
      } else {
        handleAllErrors(setErrors, error);
      }
      checkInViewAndScroll(".alert__error", htmlMain, 100);
    }
  };

  function reverseOrder() {
    reverseColours();
    const reverseChoices = poll?.choices.reverse();
    setPoll((prevState) => ({
      ...prevState,
      choices: reverseChoices
    }));
    setSortOrder(!sortOrder);
  }

  function handleLayoutChange() {
    setGridLayout(!gridLayout);
    if (!gridLayout) {
      return (pollContent.current.dataset.layout = "flex-grid");
    }
    pollContent.current.dataset.layout = "flex-stack";
  }

  function handleStyleChange() {
    let currentStyle = resultStyle;
    currentStyle++;
    if (currentStyle > 2) {
      return setResultStyle(0);
    }
    setResultStyle(currentStyle);
  }

  function reverseColours() {
    const setCols01 = chartColors[0].reverse();
    const setCols02 = chartColors[1].reverse();
    setChartColors([setCols01, setCols02]);
  }

  const getTotalVotes = () => {
    return poll?.choices?.reduce((acc, choice) => {
      return acc + choice.count;
    }, 0);
  };

  const getChoicePercentage = (selectedChoice) => {
    const totalVotes = getTotalVotes();
    // 0 / 0 returns NaN, so we need to check for that
    const choicePercentage = Math.round((selectedChoice.count / totalVotes) * 100);
    if (isNaN(choicePercentage)) return 0;
    return choicePercentage;
  };

  const getAllPieAngles = () => {
    const angles = poll?.choices?.map((choice) => {
      return (getChoicePercentage(choice) * 360) / 100;
    });
    rots = accAngles(angles);
  };

  const accAngles = (arr) => {
    let rot;
    let degs = [];
    arr.forEach((item, index) => {
      if (index > 0) {
        if (index === 1) {
          rot = arr[0];
          degs.push(rot);
        } else {
          rot += arr[index - 1];
          degs.push(rot);
        }
      } else {
        rot = 0;
        degs.push(rot);
      }
    });
    return degs;
  };

  function addModal(modal, props) {
    ModalService.open(modal, props);
  }

  if (loading) {
    return (
      <Loader
        loaderText={
          <>
            Loading poll data...
            <br />
            ...counting pixels.
          </>
        }
        width="100px"
        height="100px"
        color="var(--brand-primary)"
      />
    );
  }

  return (
    <>
      <main ref={htmlMain}>
        <div className="view-poll">
          {poll ? (
            <div className="view-poll__panel poll-card trapezium">
              <CategoryBadge category={poll?.category} />
              <PollTitle Tag={"h1"} title={poll.title}>
                <TotalVotes
                  totalVotes={getTotalVotes()}
                  category={`${poll?.category.toLowerCase()}`}
                />
              </PollTitle>

              {(poll?.hasVoted || poll?.documentStatus === "expired") && (
                <div className="poll-card__actions">
                  <ul>
                    <li>
                      <ActionButton
                        key="info"
                        label="Info"
                        icon="OTC"
                        action={() =>
                          addModal(ModalInfo, {
                            title: "Poll information",
                            poll,
                            totalVotes: getTotalVotes()
                          })
                        }
                      />
                    </li>
                    <li>
                      <ActionButton
                        key="style"
                        label="Style"
                        icon="Style"
                        action={handleStyleChange}
                      />
                    </li>
                    <li>
                      <ActionButton
                        key="layout"
                        label="Layout"
                        icon={["Layout-Stack", "Layout-Grid"]}
                        trackedState={gridLayout}
                        action={handleLayoutChange}
                      />
                    </li>
                    <li>
                      <ActionButton
                        key="chart"
                        label="Chart"
                        icon="Chart"
                        action={() =>
                          addModal(ModalChart, {
                            title: "Poll chart",
                            poll,
                            className: "chart-holder",
                            chartColors
                          })
                        }
                      />
                    </li>
                    <li>
                      <ActionButton
                        key="sort"
                        label="Sort"
                        icon={["Sort-Asc", "Sort-Desc"]}
                        trackedState={sortOrder}
                        action={reverseOrder}
                      />
                    </li>
                    <li>
                      <ActionButton
                        key="share"
                        label="Share"
                        icon="Share"
                        action={() =>
                          addModal(ModalShare, {
                            title: "Share poll",
                            poll
                          })
                        }
                      />
                    </li>
                  </ul>
                </div>
              )}
              <div className="view-poll__content" ref={pollContent} data-layout="flex-stack">
                {poll?.choices && resultStyle === 2 && getAllPieAngles()}
                {(poll?.hasVoted || poll?.documentStatus === "expired") &&
                  poll?.choices?.map((choice, index) => {
                    return (
                      <DataStyle
                        key={choice._id}
                        resultStyle={resultStyle}
                        choice={choice}
                        chartColor={chartColors[1][index]}
                        percentage={getChoicePercentage(choice)}
                        rot={rots[index]}
                      >
                        {poll?.votedFor?.split("_")[0] === choice._id ? (
                          <VotedFor category={poll?.category} />
                        ) : null}
                      </DataStyle>
                    );
                  })}
                {!poll?.hasVoted &&
                  poll?.documentStatus !== "expired" &&
                  poll?.choices.map((choice) => (
                    <VotePanel key={choice._id} choice={choice} vote={vote} />
                  ))}
              </div>
            </div>
          ) : null}
        </div>
        {errors.length > 0 && (
          <AlertError errors={errors} handleAlertClose={() => handleAlertClose(setErrors)} />
        )}
        {success.status === "success" && (
          <AlertSuccess
            success={success}
            poll={poll}
            handleAlertClose={() => handleAlertClose(setSuccess)}
          />
        )}
      </main>
      {/*{poll && <p className="data-view">{JSON.stringify(poll, null, 2)}</p>}*/}
    </>
  );
}
